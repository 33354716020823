.optimisesectionChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background: linear-gradient(103.14deg, #11a3ae, #3ac4c4 33.33%, #23ccd7 66.68%, #0097c7);
    width: 1280px;
    height: 480px;
}
.optimisebackground2 {
    position: absolute;
    top: -284px;
    left: 925.34px;
    border-radius: 50%;
    background-color: rgba(53, 131, 188, 0.8); 
    filter: blur(130px);
    width: 686.88px;
    height: 650.33px;
    transform:  rotate(9.32deg);
    transform-origin: 0 0;
    opacity: 0.5;
}
.optimisebackground3 {
    position: absolute;
    top: -87px;
    left: 253.34px;
    border-radius: 50%;
    background-color: rgba(53, 131, 188, 0.8); 
    filter: blur(130px);
    width: 686.88px;
    height: 650.33px;
    transform:  rotate(9.32deg);
    transform-origin: 0 0;
    opacity: 0.65;
}
.optimiseornamentIcon {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1440px;
    height: 857px;
    opacity: 0.7;
}
.optimisesectionItem {
    position: absolute;
    top: 106.8px;
    margin-left: 90px;
}
.optimiselabel {
    text-decoration: none;
    color: white ;
    position: relative;
    line-height: 24px;
    font-weight: 500;
}
.optimisebutton {
    position: absolute;
    top: 148px;
    left: 0px;
    border-radius:  8px; 
    background: linear-gradient(95.02deg, #116595, #09617d);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px 24px;
}
.optimiselistWithUs {
    position: relative;
    text-transform: uppercase;
    font-weight: 500;
}
.optimisetrackAnyHashtags1 {
    position: relative;
    font-size: 44px; 
    line-height: 50px;
    font-weight: 600;
    font-family: 'Inter', sans-serif ; 
    color: #fff; 
    display: inline-block;
    width: 524px;
    height: 99px;
    flex-shrink: 0;
}
.optimiselistWithUsParent {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.optimiseframeWrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 524px;
    height: 129px;
    font-size: 20px;
    color: #f6f8fb; 
    font-family:  'Poppins', sans-serif ; 
}
.optimisebuttonParent {
    position: absolute;
    top: 98px;
    left: 80px;
    width: 524px;
    height: 204px;
}
.optimiseframeChild {
    position: absolute;
    top: 0px;
    left: 0px;
    overflow: hidden;
    border-radius: 18px;
    background-color: #fff; 
    width: 370.64px;
    height: 376.43px;
}
.optimiselabel1 {
    flex: 1;
    position: relative;
    line-height: 18px;
    font-weight: 500;
}
.optimiselabelWrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.optimiseplaceholder {
    flex: 1;
    position: relative;
    line-height: 24px;
}
.optimiseinput {
    align-self: stretch;
    border-radius: 7.57px;
    background-color: #fff; 
    border: 0.9px solid #9aa8bc; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 11.349533081054688px 15.132710456848145px;
    font-size: 16px;
}
.optimisetextField {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 699.89px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 3.78px;
}
.optimisechevronDownIcon2 {
    position: relative;
    width: 22.7px;
    height: 22.7px;
}
.optimiseicon {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.optimiseinput1 {
    align-self: stretch;
    border-radius: 7.57px;
    background-color: #fff; 
    border: 0.9px solid #9aa8bc; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 11.349533081054688px 15.132710456848145px;
    gap: 15.13px;
    font-size:  16px; 
}
.optimiseselectField {
    width: 340.49px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 3.78px;
}
.optimiseselectFieldParent {
    position: absolute;
    top: 85.12px;
    left: 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 18.92px;
}
.optimisetextFieldParent {
    position: absolute;
    top: 181.59px;
    left: 841.76px;
    width: 699.89px;
    height: 152.6px;
}
.optimisestoreInformation {
    align-self: stretch;
    position: relative;
    line-height: 17.02px;
    font-weight: 600;
}
.optimisestoreChild {
    position: relative;
    border-radius:  8px; 
    background-color: rgba(83, 193, 202, 0.4); 
    width: 300px;
    height: 10px;
}
.optimisestore {
    position: absolute;
    top: 0px;
    left: 0px;
    border-bottom: 0.9px solid #cbd4e1; 
    box-sizing: border-box;
    width: 370.64px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 22.699066162109375px;
    gap: 16px;
    font-size: 15.13px;
    color: #1a212b; 
}
.optimisegroupChild {
    position: absolute;
    top: 23px;
    left: 0px;
    // border-radius: 14px; 
    background-color: #fff; 
    border: 0.9px solid rgba(154, 168, 188, 0.2);
    box-sizing: border-box;
    width: 348px;
    overflow: hidden;
    height: 60px;
}
.optimisegroupItem {
    position: absolute;
    top: 0px;
    left: 1px;
    border-radius: 8px; 
    background-color:rgba(154, 168, 188, 0.2);
    width: 153px;
    height: 13px;
}
.optimiserectangleGroup {
    position: absolute;
    top: 319px;
    left: 22.64px;
    width: 404px;
    height: 83px;
}
.optimiseframeItem {
    position: relative;
    border-radius: 8px; 
    background-color: #ffffff;
    border: 0.9px solid rgba(154, 168, 188, 0.2); 
    box-sizing: border-box;
    width: 162px; 
    overflow: hidden;
    height: 60px;
}
.optimiserectangleContainer {
    position: absolute;
    top: 23px;
    left: 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
}
.optimisegroupInner {
    position: absolute;
    top: 0px;
    left: 1.36px;
    border-radius: 8px; 
    background-color: rgba(83, 193, 202, 0.4); 
    width: 190px;
    height: 13px;
}
.optimiseframeParent {
    position: absolute;
    top: 212px;
    left: 22.64px;
    width: 488px;
    height: 83px;
}
.optimiserectangleParent1 {
    position: absolute;
    top: 105px;
    left: 22.64px;
    width: 404px;
    height: 83px;
}
.optimiserectangleParent {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 18.92px;
    width: 407.64px;
    height: 376.43px;
    overflow: hidden;
}
.optimisegroupDiv {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 407.64px;
    height: 376.43px;
}
.optimisegroupWrapper {
    position: absolute;
    top: 0px;
    left: 118px;
    width: 407.64px;
    height: 376.43px;
}
.optimisegroupChild2 {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 11.35px 11.35px 0px 0px;
    background-color:#fff; 
    border: 1px solid #f6f8fb; 
    box-sizing: border-box;
    width: 283.74px;
    height: 60.53px;
}
.optimisestoreLocation {
    position: absolute;
    top: 0px;
    left: 26.48px;
    line-height: 22.7px;
    font-weight: 500;
}
.optimisemappinIcon {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 22.7px;
    height: 22.7px;
}
.optimisestoreLocationParent {
    position: absolute;
    top: 11.35px;
    left: 15.13px;
    width: 132.48px;
    height: 23px;
}
.optimisemapIcon {
    position: absolute;
    top: calc(50% - 112.08px);
    left: 0px;
    border-radius: 8.29px;
    width: 100%; 
    height: auto;
  //  height: 608.15px;
    object-fit: cover;
}
.optimisemapWrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 11.35px;
    background-color:#fff; 
    border: 0.9px solid #cbd4e1; 
    box-sizing: border-box;
    width: 283.74px;
    height: 224.15px;
    overflow: hidden;
}
.optimiseframeWrapper1 {
    position: absolute;
    top: 45.4px;
    left: 0px;
    width: 283.74px;
    height: 224.15px;
}
.optimiserectangleParent2 {
    position: absolute;
    top: 81px;
    left: 0px;
    box-shadow: 0px 3.783177614212036px 7.57px rgba(0, 0, 0, 0.16);
    width: 283.74px;
    height: 269.55px;
    font-size: 15.13px;
    color: var(--text-primary);
}
.optimisegroupParent {
    position: absolute;
    top: 74px;
    left: 650px ;
    width: 525.64px;
    height: 376.43px;
    font-size :12px;
    color:#1a212b;
}
.optimisesection {
    position: relative;
    border-radius: 20px;
    transform: scale(0.9); /* Decrease the size of the container and its contents */
    height: 400px;
    overflow: hidden;
    margin-bottom: 40px ;
  //   margin: 70px;
    text-align: left;
    font-size: 16px;
    color: #eceff4;
    font-family: 'Inter', sans-serif;
  }
  
  .optimisesection > * {
    transform: scale(1.1); /* Counteract the scaling for child elements */
  }
  

