
.business-container{
    max-width: 1200px;
    width: 100%;
    margin: 2em auto;
}

.business-content-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3em auto;
    font-family: Inter, sans-serif;
}

.business-title{
    font-size: 3rem;
    font-weight: 700;
    margin: 0.25em 0 0.75em;
}

.business-highlight{
    font-size: 0.8rem;
    margin: 0.5em 0 ;
    letter-spacing: .12em;
}

.business-title-span{
    color: #09a7b3;
    margin: 0 0.2em;
}

.business-content{
    width: 500px;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 1em 0 2em ;
    font-size: 1rem;
}

.business-icon-arrow{
    cursor: pointer;
}

.business-synergy-btn{
    display: flex;
    gap: 1em;
    align-items: center;
}

.synergy-btn{
    text-decoration: none;
}

@media screen and (max-width: 768px) {

    .business-title{
        font-size: 1.85rem;
    }

    .business-content{
        width: 300px;
        font-size: 0.8rem;
    }
    
}

@media screen and (max-width: 500px) {

    .business-content{
        width: 200px;
        font-size: 0.8rem;
    }

    .synergy-btn{
        padding: 0;
        font-size: 1rem;
    }

    .business-synergy-btn{
        width: 200px;
        display: flex;
        gap: 1em;
        justify-content: center;
        align-items: center;
    }
    
    .business-icon-arrow{
        width: 24px;
    }
}