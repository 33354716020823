.statisticsstatsChild {
    position: absolute;
    left: 0px;
   background-color: #09a7b3;
    border: 1px solid #09a7b3;
    box-sizing: border-box;
    width: 1440px;
    height: 256px;
    
}
.statisticsicon {
    position: relative ;
    top: 43px;
    margin-left: 1360px;
    height: 170.56px;
}
.statisticsstatsItem {
    position: absolute;
    top: 2.5px;
    left: 320px;
    width: 2px;
    height: 251.5px;
}
.statisticsourPowerfull {
    margin: 0;
}
.statisticsweSpeakWithContainer1 {
    line-break: anywhere;
    width: 100%;
    color:  rgb(255, 255, 255) ;
}
.statisticsweSpeakWithContainer {
    position: absolute;
    top: calc(50% - 82px);
    left: 65px;
    font-size: 22px;
    line-height: 24px;
    font-weight: 600;
    display: flex;
    align-items: center;
    width: 231px;
    height: 164px;
  //  -webkit-text-stroke: 1px #09a7b3;
}
.statisticsdiv {
    position: absolute;
    top: 0px;
    left: -40px;
    line-height: 72px;
    font-weight: 600;
    display: inline-block;
    width: 143px;
   // -webkit-text-stroke: 1px #09a7b3;
}
.statisticsbrandsTrustUsWrapper {
    position: absolute;
    top: 88px;
    margin-top: -20px;
    left: -40px;
    width: 118px;
    height: 18px;
      font-size:  14px ;
}
.statisticsparent {
    position: absolute;
    top: 75px;
    left: 386px;
    
    width: 143px;
    height: 106px;
}
.statisticsdiv1 {
    position: absolute;
    top: 0px;
    left: -50px;
    line-height: 72px;
    color: #fff;
    font-weight: 600;
    font-size: 50px;
    display: inline-block;
     width: 200px;
   // -webkit-text-stroke: 1px #09a7b3;
}
.statisticsdiv{
    font-size: 50px ;
    color: #fff;
}
.statisticsstoresListedWithUsWrapper {
    position: absolute;
    margin-top : 70px;
    left: -50px;
    width: 158px;
    
    height: 18px;
    font-size:  14px ;
}
.statisticsgroup {
    position: absolute;
    top: 75px;
    left: 715px;
    width: 200px;
    height: 106px;
}
.statisticsdiv2 {
    position: absolute;
    top: 0px;
    font-size: 50px ;
    left: -85px;
    line-height: 72px;
    font-weight: 600;
    color: #fff;
  //  -webkit-text-stroke: 1px #09a7b3;
}
.statisticsshelvesAvailable {
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 18px;
    font-weight: 600;
  //  -webkit-text-stroke: 1px #09a7b3;
}
.statisticsshelvesAvailableWrapper {
    position: absolute;
    top: 88px;
    margin-top : -20px;
    left: -80px;
    width: 134px;
    height: 18px;
    font-size:  14px ;
}
.statisticscontainer {
    position: absolute;
    top: 75px;
    left: 1052px;
    width: 172px;
    height: 106px;
}
.statisticsstats {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1489.98px;
    height: 256px;
}
.statisticsstatsParent {
    position: relative;
    width: 100%;
    height: 256px ;
    overflow: hidden;
    text-align: left;
    color:#fff; 
    font-family: 'Inter' , sans-serif ;
}

