.aboutgoalourMission{
    display: flex; 
    flex-direction: column;
    margin: 70px ;
    margin-left: 120px ;
}

.aboutgoalvalueProps{
    display: flex;
    flex-direction: row;
    font-family: 'Inter', sans-serif;  
}

.aboutgoalrectangleParent{
    border-radius:20px;
    margin: 30px ;
    background-color: #fff;
    border: 1px solid #09a7b3;
    box-sizing: border-box;
    width: 94%;
    height: 305px;
   text-align: center;
}

.aboutgoalourMission1{
    font-size: 48px ;
    font-family: "Poppins",sans-serif;
    letter-spacing: -0.02em;
    line-height: 58px;
    font-weight: 600;
    width: 800px;
 margin-left: 500px;
}

.aboutgoalmission{
    color: #09a7b3;
}
.aboutgoalourMissionIs1{
    align-self: stretch;
    font-size: 16px;
    margin: 30px ;
    line-height: 24px;
  text-align: center;
    font-family: 'Inter', sans-serif; 
    color:#728197; 
}

.aboutgoalsectionHeading {
    align-self: stretch;
    align-items: center;
    justify-content: space-between;

    
}
.aboutgoalsynergyInRetailContainer{

    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    text-align: center;
 
}
.aboutgoaldiv1{
    line-height: 18px;
    font-weight: 600;
     margin : 20px;
   text-align: left;

}
.aboutgoalshoppingcartIcon{
    padding-top: 50px;
}
/* Media queries for responsive design */
@media only screen and (max-width: 1200px) {
   
    .aboutgoalourMission {
        margin-left: 60px;
    }

    .aboutgoalourMission1 {
        margin-left: 100px;
        width: 600px;
    }
}

@media only screen and (max-width: 768px) {
    .aboutgoalsynergyInRetailContainer{

        font-size: 25px;
        line-height: 40px;
        font-weight: 600;
        text-align: center;
        padding-bottom: 20px ;
     
    }
    .aboutgoalourMission {
        margin: 20px;
        margin-left: 12%;
    }
.aboutgoalourMissionIs1{
  width: 350px ;
  margin: 20px;
  margin-left: -10px;
  font-size: 13px ;
}
    .aboutgoalvalueProps {
        flex-direction: column;


    }

    .aboutgoalourMission1 {
        margin-left: 20%;
        width: 100%;
        font-size: 30px;
    }

 
    .aboutgoalrectangleParent {
        width: 80% !important;
        height: 70% !important;
        max-width: 380px;
    }
}