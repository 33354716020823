.sdpshopOverview {
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: flex;
    align-items: center;
    width: 800px;
}
.sdpspaciousAndBright {
    margin: 0;
}
.sdpcomeAndStayContainer {
    align-self: stretch;
    position: relative;
    font-size: 14px;
    line-height: 24px;
    color: #728197;
}
.sdplink {
    position: relative;
    text-decoration: underline;
    line-height: 20px;
    width: 100px ;
}
.sdplinkCell {
    width: 70.79px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 14px;
    color: #09a7b3;
}
.sdpshopOverviewParent {
    position: relative;
    border-radius: 16px;
    background-color:#f6f8fb;
    width: 850px ;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 20px;
    margin : 50px ;
    box-sizing: border-box;
    gap: 16px;
    text-align: left;
    font-size: 22px;
    color: #1a212b;
    font-family: "Inter" , sans-serif ;
}


///resoponsive design 
@media (max-width: 768px) and (min-width: 320px) {
    .sdpshopOverview {
        position: relative;
        line-height: 24px;
        font-weight: 600;
        display: flex;
        align-items: center;
        width: 800px;
    }
  
   
    .sdpshopOverviewParent {
        border-radius: 14px;
        width: 370px ;
        padding: 16px 20px;
        margin : 30px ;
        box-sizing: border-box;
        gap: 16px;
        text-align: left;
        font-size: 20px;
        color: #1a212b;
        font-family: "Inter" , sans-serif ;
    }
    

}