.synergydatatile1{
    display: flex; 
    flex-direction: row; 

}
.controlsynery{
    display: flex;
    font-family: "Inter",sans-serif; 
}
.arrayofline{
    width: 700px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}
.line1synergyyes { 
    border-radius: 9px;
    background-color: #09a7b3;
    width: 130px;
    height: 2px;
    }
    .line1synergy {
    border-radius: 9px;
    background-color: rgba(154, 168, 188, 0.3);
    width: 130px;
    height: 2px;
    }
.synergydataright{
    display: flex;
    flex-direction: column;
    gap : 50px ;
    margin: 50px ;
}
.synergyrightfind {
    width: 125px;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 19, 37, 0.64);
    }
    .synergytell{
        width: 730px;
        font-size: 32px;
        line-height: 36px;
        font-weight: 600;
        color: #1a212b;
        display: inline-block;
        } 
        .fourtsynergyoption{
            display: flex;
            flex-direction: column; 
            gap :12px   ; 
          }
          .synergy3headoption{
            font-size: 12px;
            line-height: 18px;
            font-weight: 500;
            color: #728197;
            }
            .checkbox-container{
                border-radius: 8px;
                width: 250px ;
                height: 44px;
                text-align: left;
                background-color: #fff;
                border: 1px solid #cbd4e1;
                box-sizing: border-box; 
                padding: 12px;
                border-radius: 10px;
                cursor: pointer;
                transition: border-color 0.3s ease;
            }
            .checkbox-container.selected {
                border-color: #09A7B3; /* Change the border color when selected */
              }
         //buttons
         .synergybutton{
            margin-top: 90vh;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap : 600px ;
            margin-left: -780px;
            cursor: pointer;
         }
        
         #synergybuttonid{
            padding: 16px ; 
            background-color: rgba(255, 255, 255, );
            border:1px solid rgb(0, 0, 0) ;
            border-radius: 8px ;
            cursor: pointer;
            font-size: 16px;
            height: 50px;
            width: 140px ;
         }
        
        #synergybuttonid:hover{
             border-color: #09A7B3;
             background: #09A7B3;
              .synergyprevbutton{
                color: #ffffff;
              }
        
            }

         .optionsynergy {
            display: grid;
            grid-template-columns: repeat(3, 1fr); /* 3 columns in each row */
            gap: 10px; /* Adjust the gap between elements */
          }
          .checkbox13-container {
            background-color: #fff;
            border: 1px solid #cbd4e1;
            box-sizing: border-box; 
            padding: 12px;
            width: 200px;
            border-radius: 10px;
            cursor: pointer;
          }
          .checkbox13-container.selected {
            border-color: #09A7B3; /* Change the border color when selected */
          }

          //tile1
        .synergyicontile{
            cursor: pointer;
            border: 1px solid #cbd4e1;
            padding-top: 15px ;
            padding-bottom: 15px ;
            border-radius: 8px; 
            align-items: center;
            padding-left: 12px ;
            padding-right: 12px ;
            display: flex;
            font-size: 12px; 
        }
      
  .synergyicontile.selected {
   
    border: 2px solid #09A7B3 ;
    
  }
 
  .optionsynergytile1{
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 3 columns in each row */
    gap: 10px; /* Adjust the gap between elements */
    width: 700px;

  }
 
.Sidebar1{
  height: 100vh  !important;
}
.Sidebar2{
  height: 100vh  !important;
}
.Sidebar3{
  height: 100vh  !important;
}
.Sidebar4{
  height: 100vh  !important;
}
.Sidebar5{
  height: 100vh  !important;
}
  // media qwery 
  @media (max-width: 768px) and (min-width: 320px) {
    /* Apply the following styles for screens wider than 768px (adjust the value as needed) */
    .synergydatatile1{
      display: flex;  
      flex-direction: column;

     
  }
  .synergydataleft{
    display: none;
   
   
  }

    .arrayofline {
      margin-top: 10px ;
      display: flex; 
 
    }
    .line1synergy {
      width: 50px;
      
    
      }
      .synergytell{
        width: 330px;
        font-size: 20px;
        line-height: 36px;
        font-weight: 600;
        color: #1a212b;
        display: inline-block;
        } 

      .line1synergyyes { 
        width: 50px;
        }
    .synergybutton{
      margin-top: 780px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: var(--initial-gap); 
      margin-left: -750px;
      cursor: pointer;
     
    
   }
   :root {
    --initial-gap: 140px; /* Initial gap value */
    --max-width: 765px; /* Maximum width for increasing gap */
  }
   #synergybuttonid{
      padding: 10px ; 
      background-color: rgba(255, 255, 255, );
      border:1px solid rgb(0, 0, 0) ;
      border-radius: 8px ;
      cursor: pointer;
      font-size: 16px;
      height: 40px;
      width: 100px ;
   }
  
  #synergybuttonid:hover{
       border-color: #09A7B3;
       background: #09A7B3;
        .synergyprevbutton{
          color: #ffffff;
        }
  
      }
      .optionsynergytile1{
        display: flex;
      //  grid-template-columns: repeat(4, 1fr); /* 3 columns in each row */
      flex-direction: column;
        gap: 10px; /* Adjust the gap between elements */
        width: 200px;
    
      }
      .optionsynergy {
        display:flex;
        flex-direction: column;
      //  grid-template-columns: repeat(3, 1fr); /* 3 columns in each row */
        gap: 10px; /* Adjust the gap between elements */
      }
      .controlsynery{
       //height: 900px !important;
   width: 400px !important ;
   overflow: hidden;
      }

  }
  