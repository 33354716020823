.aboutbuilding{
   
     height: 50vh;
     width: 100% ;
     background: rgb(36,176,187);
     background: linear-gradient(106deg, rgba(36,176,187,1) 9%, rgba(36,148,187,1) 23%, rgba(36,186,187,1) 47%, rgba(36,140,187,1) 66%, rgba(36,187,180,1) 93%, rgba(36,140,187,1) 100%, rgba(148,22,192,1) 100%);
 z-index : -1 ;
 padding-top:136px ;
}


.building{
  
    font-size: 32px; 
    line-height: 36px;
    font-weight: 600;
    color : #ffffff;
    text-align: center;
    display: flex;

    left: 260px ;
    align-items: center;
    justify-content: center;

    font-family: 'Inter', sans-serif ;

}
.marketplace{
   
        font-size: 50px;
        letter-spacing: 0.01em;
        line-height: 140%;
        text-transform: capitalize;
        font-weight: 800  !important;
        width: 770px;
        margin-left: 330px ;
        height: 180px;
       font-family: 'Poppins' , sans-serif ;
        color: #125465;
        text-align: center;
        margin-top: 0px ;
        display: flex;
        align-items: center;
        justify-content: center;

}


@media (max-width: 768px) and (min-width: 320px) {


    .aboutbuilding{
        height: 300px;
        width: 440px;
        overflow : hidden;
        background: rgb(36,176,187);
        background: linear-gradient(106deg, rgba(36,176,187,1) 9%, rgba(36,148,187,1) 23%, rgba(36,186,187,1) 47%, rgba(36,140,187,1) 66%, rgba(36,187,180,1) 93%, rgba(36,140,187,1) 100%, rgba(148,22,192,1) 100%);
    z-index : -1 ;
    padding-top:50px ;
   }
   
   
   .building{
     
       font-size: 25px; 
       line-height: 36px;
       font-weight: 600;
       color : #ffffff;
       text-align: center;
       display: flex;
   
       left: 40px ;
       align-items: center;
   
   }
   .marketplace{
      
           font-size: 30px;
           letter-spacing: 0.01em;
           line-height: 140%;
           text-transform: capitalize;
           font-weight: 800  !important;
           width: 440px;
           margin-left: 0px ;
           height: 180px;
          font-family: 'Poppins' , sans-serif ;
           color: #125465;
           text-align: center;
           margin-top: 0px ;
           display: flex;
           align-items: center;
           justify-content: center;


 } }