

.rent-container {
    position: relative;
    max-width: 1200px;
    width: 100%;
    margin: 2em auto;
    height: auto;
    font-family: Inter, sans-serif;
    display: flex;
    justify-content: center;
    /* border: 5px solid black; */
}


.rent-shelf-bg img {
    width: 100%;
    height: auto;
    display: block;
}


.rent-text-container { 
    /* border: 5px solid red; */
    position: absolute;
    top: 40%; 
    left: 30%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 0.25em;
    max-width: 80%;
}

.beta-message {
    width: 70%;
    border-radius: 20px;
    background-color: #e9ebec;

}

.rent-beta-message {
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 0.875em;
    padding: 0.3em;
    color: #125465;
    font-weight: 600;
}

.rent-new-notifier{
    width: 30%;
    font-size: 0.75rem;
    padding: 0.2em 0;
    text-align: center;
    background-color: #125465;
    color: #ffffff;
    border-radius: 20px;
    font-weight: 300;
}

.rent-text-heading{
    font-size: 4.5rem;
    font-weight: 600;
    color: #125465;
    margin: .25em 0;
}

.text-heading-first{
    display: flex;
    gap: 0.2em;
}

.text-highlight-shelf{
    display: flex;
    flex-direction: column;
    align-items: center;

    animation: floatAnimation 3s ease-in-out infinite;
}

.rent-shelf-text{
    font-weight: 600;
    color: #ffffff;
}

.rent-shelf-smile-image{
    display: flex;
    justify-content: center;
    align-items: center;
}


.rent-shelf-smile-image img{
    width: 100%;
}

.rent-text-subheading{
    font-size: 1rem;
    color: #ffffff;
    margin: 1em 0 2em;
}

.rent-btn-search{
    display: flex;
    align-items: center;
    gap: 1.5em;
    font-size: 1rem;
}

.rent-synergy-btn{
    padding: 0.75em;
    border-radius: 5px;
    background: linear-gradient(95.02deg,#116595,#09617d);
    color: #ffffff;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    border: none;
}

.rent-synergy-search{
    display: flex;
    align-items: center;
    gap: 0.75em;

    border-radius: 25px;
    border: none;
    background-color: #ffffff;
    padding: 0.75em;
}

.rent-synergy-search > img{
    width: 30px;
}

.rent-synergy-search-bar{
    width: 100%;
    border: none;
    outline: none;
}

.rent-synergy-search-bar:focus{
    outline: none;
}


@media screen and (max-width: 1024px){

    .rent-text-container {
        top: 43%;
        left: 30%;
        transform: translate(-50%, -50%);
    }

    .text-heading-first{
        gap: 0;
    }
    
    .rent-text-heading{
        font-size: 3rem;
        margin: .25em 0;
    }

    .beta-message{
        width: 80%;
    }

    .rent-beta-message{
        font-size: 0.75rem;
    }

    .rent-new-notifier{
        font-size: 0.625rem;
    }

    .text-highlight-shelf{
        width: 40%;
    }

    .rent-shelf-smile-image img{
        width: 70%;
    }

    .rent-text-subheading{
        font-size: 0.875rem;
    }
    
    .rent-synergy-btn{
        font-size: 0.75rem;
    }

    .rent-synergy-search{
        font-size: 0.75rem;
        padding: 0.625em 0.5em;
    }


    .rent-synergy-search > img{
        width: 20px;
    }

    .rent-synergy-search-bar{
        width: 90%;
    }
}

@media screen and (max-width: 768px) {

    .rent-container{
        width: 100%;
        /* height: 50vh; */
        background-color: #09a7b3; 
        background-image: linear-gradient(103deg, #11A3AE 0.47%, #3AC4C4 33.78%, #23CCD7 67.09%, #0097C7 100.38%);

        margin: 0;
        max-width: none;
    }

    .rent-text-container {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-align: center;
    }

    .text-highlight-shelf{
        width: 30%;
    }

    .rent-text-heading{
        font-size: 2rem;
        margin: .25em 0;
    }

    .text-heading-first{
        justify-content: center;
    }

    .beta-message{
        width: 90%;
        margin: 0 auto 0.625em;
    }
    
    .rent-beta-message{
        font-size: 0.75rem;
    }

    .rent-new-notifier{
        font-size: 0.625rem;
    }

    .rent-text-subheading{
        font-size: 0.875rem;
    }
    
    .rent-btn-search{
        justify-content: center;
        align-items: flex-end;
    }

    .rent-synergy-btn{
        font-size: 0.75rem;
    }


}

@media screen and (max-width: 495px) {

    .rent-container{
        /* width: 100%; */
       
    }

    .rent-text-container {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        
        width: 100%;
        text-align: center;
    }
    
    .rent-text-heading{
        font-size: 1.25rem;
        margin: .25em 0;
    }

    .text-heading-first{
        justify-content: center;
    }

    .beta-message{
        width: 100%;
        margin: 0 auto 0.25em;   
    }
    
    .rent-beta-message{
        font-size: 0.5rem;
    }

    .rent-new-notifier{
        font-size: 0.5rem;
    }

    .rent-text-subheading{
        font-size: 0.625rem;
    }
    
    
    .rent-btn-search{
        justify-content: center;
        /* align-items: baseline; */
        gap: 0.5em;
    }
    
    .rent-synergy-btn{
        font-size: 0.5rem;
        padding: 0.5em 1em;
    }


    .rent-synergy-search > img{
        width: 10px;
    }

    .rent-synergy-search{
        align-items: center;
    }
    
    .rent-synergy-search-bar, .rent-synergy-search{
        padding: 0.25em;
        font-size: 0.5rem;
    }
}

  
@keyframes floatAnimation {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-15px); 
    }
}