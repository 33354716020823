
.brand-image-container {
    width: 50%;
    display: flex;
    justify-content: center;
}

.image-background{
    width: 100%;
    position: relative;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(6, 1fr); 
    grid-template-rows: repeat(5, 1fr); 
}

.brand-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    grid-column: 1 / -1;
    grid-row: 1 / -1;
}

.float-container-left{
    display: flex;
    flex-direction: column;
    grid-column: 1 / 5;
    grid-row: 3 / -1;
    gap: 1em;
}

.timer-icon, .success-icon{
    width: 30px;
}

.timer{
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    width: 150px;
}


.timer,
.success, 
.shelfs-count,
.income{
    background-color: white;
    border-radius: 10px;
    border: 1px solid #728197;  
    padding: 0.5em 1em;
}

.week, .shelfs-count-number{
    color: #09a7b3;
    font-weight: 600;
    font-size: 1.25rem;
}

.shelf-life, .income-text{
    color: #728197;
    font-size: 0.85rem;
}

.success, .money-success{
    display: flex;
    align-items: center;
    gap: 0.75rem;
}


.float-container-right{
    display: flex;
    flex-direction: column;
    align-items: end;
    grid-column: 4 / -1;
    grid-row: 2 / -1;
    gap: 1em;
}

.shelfs-count{
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.shelf-text{
    font-size: 0.85rem;
}

.income{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;
}

.income-body{
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.income-value{
    color: black;
    font-weight: 600;
    font-size: 1.25rem;
}

.bag-open{
    grid-column: 6 / -1;
    grid-row: 1 / 2;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.chart-line{
    grid-column: 6 / -1;
    grid-row: 3 / 4;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}


@media (max-width:800px) {
    .brand-image-container {
        width: 80%;
        margin: 0 auto;
    }
    
}

.image-background{
    animation: floatAnimation 2s ease-in-out infinite;
}
  
@keyframes floatAnimation {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px); 
    }
}