.sdpselfframeChild {
   
    width: 18px;
}
.sdpselfplaceholder {
 
    position: relative;
    line-height: 20px;
}
.gtcheckout{
    color: rgb(255, 255, 255) ;
    height: 48px ;
    margin-top: -48px !important;
    margin-left: 9px ;
    text-align: center;
    
}
.sdpplaceholder{
    background:  #eff1f5;
    text-align: center;
    padding: 5px ;
    border-radius: 5px ;
  // height: 10px ;
}
.gtcheckout1{
    color: rgb(255, 255, 255) ;
    width :300px ;
    height: 48px ;
   //  margin-top: -10px !important;
    text-align: center;

    
}
.sdpselficon2 {
    position: relative;
    width: 24px;
    height: 24px;
    object-fit: cover;
}
.sdpselficon1 {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.sdpselfinput {
    align-self: stretch;
    border-radius: 8px;
    background-color:#fff;
    border: 1px solid #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 16px; 
    gap: 16px; 
}
.sdpselfselectField {
    width: 228px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
    font-size: 14px; 
}
.sdpselfframeParent {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 20px 64px;
    box-sizing: border-box;
    text-align: left;
   
    font-size: 22px; 
    color: #1a212b;
    font-family: "Inter",sans-serif ;
} 


.sdpselflabel {
    position: relative;
    line-height: 18px;
    font-weight: 500;
}
.sdpselflabelWrapper {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.sdpselfchip {
    border-radius:  8px; 
    background-color: #fff;
    border: 1px solid #cbd4e1;
  
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding:  8px  12px;
    cursor:  pointer;
}
.sdpselfchip:hover{
    border: 1px solid #09a7b3;

}
.sdpselfchipCell {
    width: 39px;
    height: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding:16px; 
    box-sizing: border-box;
    color:  #09a7b3;
}
.sdpselfchip1 {
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #cbd4e1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px  12px;
}
.sdpselfchipCell1 {
    width: 59px;
    height: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px;
    box-sizing: border-box;
}
.sdpselfchipCell2 {
    width: 69px;
    height: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px ;
    box-sizing: border-box;
}
.sdpselfchipCellParent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px; 
    font-size: 12px;
}
.sdpselfframeDiv {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}
.sdpselfrackChild {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 16px; 
    background-color: #fff; 
    border: 1px solid #cbd4e1; 
    box-sizing: border-box;
    width: 840px;
    height: 148px;
}
.sdpselfrack:hover .sdpselfrackChild {
    border-color: #09a7b3;
  }
.sdpselfrack1 {
    position: relative;
    line-height: 18px;
    font-weight: 600;
}
.sdpselfthisIsA3 {
    position: relative;
    line-height: 24px;
    color: rgba(0, 19, 37, 0.64);
}
.sdpselfrack1Parent {
    width: 480px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap:8px; 
}
.sdpselfdiv {
    position: relative;
    line-height: 24px;
    font-weight: 600;
}
.sdpselfshelf {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
    width: 44px;
    flex-shrink: 0;
}
.sdpselfparent {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap:  4px;
}
.sdpselfframeWrapper {
    position: relative;
    width: 92px;
    height: 24px;
    font-size:22px; 
}
.sdpselfframeParent2 {
    position: absolute;
    top: 25px;
    left: 148px;
    height: 99px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap:24px;
}
#sdpselfcheckboxChild {
    position: relative;
    border-radius: 4px; 
    background-color: #fff; 
    border: 1px solid #9aa8bc;
    box-sizing: border-box;
    width: 24px;
    height: 24px;
   
}
.sdpselflabel3 {
    position: relative;
    line-height: 24px;
}
.sdpselfcheckbox1 {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap:  8px;
}
.sdpselfcheckbox {
    position: absolute;
    top: 62px;
    left: 680px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.sdpselfrack {
    position: relative;
    width: 800px;
    font-family: "Inter",sans-serif;
    height: 148px;
}
.sdpselfrackInner {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 16px;
    background-color: #fff;
    border: 1px solid  #09a7b3;
    box-sizing: border-box;
    width: 840px;
    height: 148px;
}



.sdpselfgroupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius:  4px; 
    background-color: #09a7b3;
    width: 24px;
    height: 24px;
}
.sdpselfgroupItem {
    position: absolute;
    top: 8px;
    left: 6px;
    width: 12px;
    height: 8px;
    object-fit: cover;
}
.sdpselfrectangleParent {
    position: relative;
    width: 24px;
    height: 24px;
}
.sdpselfrackChild1 {
    position: absolute;
    top: 24px;
    left: 22.65px;
    border-radius: 12px;
    width: 94.38px;
    height: 100px;
    object-fit: cover;
}
.sdpselfthisIsA5 {
    position: relative;
    line-height: 24px;
    color: #728197;
}
.sdpselfrackParent {
    flex-shrink: 0;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    font-size: 16px;
}
.sdpselfframeGroup {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
}




//   new 
.sdpmaincompent{
    font-family: "Inter",sans-serif;
    margin-top: 50px ;

}

.sdpbigparent{
    display: flex ;
    flex-direction: row ;
    
}
.sdpbigparent2{
    margin-top:24px ;
  
  

}
.sdpbreakframeParent{
    font-family: "Inter",sans-serif;
    width : 472px ;
    padding-left: 38px ;
    padding-right: 64px ;
    padding-top: 20px ;
}
.sdpbreakrowParent{
    border: 1px solid #9aa8bc;
    box-sizing: border-box;
 background: #f6f8fb;;
 //border-radius: 20px;
 border-top-left-radius: 20px;
 border-top-right-radius: 20px;

  //  padding: 10px ;
}
.sdpbreakattribute4{
    
    padding : 10px ;
  
}
.sdpbreakrow{
    display: flex ;
    flex-direction: row ;
    border: 1px solid #9aa8bc;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-sizing: border-box;
    padding: 10px ;
}
.sdpbreakattribute1{
    font-size: 12px ;
    color : #1a212b; 
    width: 140px;
    text-align: left;
    font-family: "Inter",sans-serif ;
    font-weight: 500;
}
.sdpbreakdiv1{
    width: 100px;
    text-align: right;
}
.sdpbreakattribute2{
    width: 140px;
   margin-left: 120px ;
}
.sdpbreakattribute1E{
    font-size: 12px ;
    color : #1a212b; 
    font-weight: 500;
}
.sdpbreakvalue{
    color :#728197; ;
    font-family: "Inter",sans-serif ; 
    font-size: 14px ;
}
.sdpbreakbookingBreakup{
     font-size:  16px;
     color : #1a212b; 
     font-family: "Inter",sans-serif ;
     font-weight: 600;
     width: 280px ;
}
.sdpbreakrow3{
    display: flex ;
    gap : 30px ;
    color :#728197; ;
    font-family: "Inter",sans-serif ; 
    font-size: 16px ;
    line-height: 25px ;
    margin-top: 10px ;
    
}

.sdpbreakrow2{
     padding  : 24px;
}
.sdpbreakdiv6{
    margin-left: 20px ;
}

.sdpbreakrow8{
  color: #1a212b;
  font-size:  16px;
  font-weight: 600;
  font-family: "Inter",sans-serif ;
  display: flex ;
 margin-top: 10px ;
}
.sdpbreakpriceDetailsWrapper{
    gap : 8px ;
}
.sdpbreaktotalShelves{
    width : 210px ;
    height: 24px ;
    
}
.sdpbreakbutton{
    border: 1px solid #9aa8bc; 
    box-sizing: border-box;
    background: #09a7b3;;
    height: 48px;
    width: 325px;
    border-radius: 10px ;
    padding: 12px 16px; 
    margin : 22px ;
}

.sdpbreakbb{
    font-family: "Inter",sans-serif;
    border: 1px solid #9aa8bc;
    box-sizing: border-box;
    border-radius: 10px ;
     margin-left: 90px ;
     width : 370px ;
     
   
}

.totalAvailableShelves {
     margin-left: 50px ;
    font-size: 22px;
    line-height: 24px;
    font-weight: 600;
    font-family: "Inter",sans-serif;
    color: #1a212b;
    text-align: left;
    }
    .totalshelfavailableandcalender{
        display: flex;
        flex-direction: row;
        gap : 350px ;
        margin-left: 20px ;
    }
    .sdpselectField{
        display: flex;
        flex-direction: row;
        gap : 16px ;
    }

    .sdptags{
        font-family: "Inter",sans-serif ;
        display: flex;
        gap : 16px ;
        margin-top: 20px ;
        margin-left: 70px ;
      
    }
    .sdptagboxall{
        width: 39px ;
        height: 34px ;
        border-radius: 8px ;
        border: 1px solid #cbd4e1; 
        box-sizing: border-box;
        padding-top: 8px;
        padding-left:12px ;
        padding-right: 12px;
        padding-bottom: 8px;
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        color: #1a212b;
        text-align: left; 
    }
    .sdptagboxall:hover{
        border: 1px solid  #09a7b3;;
    }
    .sdptagbox{
        cursor: pointer;
        width: 60px ;
        height: 34px ;
       
        border-radius: 8px ;
        border: 1px solid #cbd4e1; 
        box-sizing: border-box;
        padding-top: 8px;
        padding-left:12px ;
        padding-right: 12px;
        padding-bottom: 8px;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        font-family: Inter;
        color: #1a212b;
        text-align: left; 

    }
    .sdptagbox:hover{
        border: 1px solid  #09a7b3;;
    }


    
        .totalShelvesPicked {
           
            font-size: 12px;
            line-height: 18px;
            font-weight: 500;
            font-family: Inter;
            color: #1a212b;
            text-align: left;
            display: inline-block;
            } 
.shelves {
   
    font-size: 14px;
    line-height: 20px; 
    font-family: Inter;
    color: #728197;
    text-align: left;
    display: inline-block;
    }
    .totalshlefpickeddata{
        display: flex;
       background : #F6F8FB;
        flex-direction: column;
        padding-left : 12px ;
        padding-right : 10px ;

        padding-top : 20px ;
        padding-bottom : 20px ;
        border-bottom: 1px solid #cbd4e1; 
      
    }
    .datepickedforrent{
        height: 50px ;
        border-start-start-radius : 8px  ;
        border-start-end-radius : 8px  ;
        background : #F6F8FB;
        display: flex;
        
        border-bottom: 1px solid #cbd4e1; 
        
        padding-top: 16px;
        padding-bottom:16px ;
        padding-left:12px ;
        padding-right:12px;

    }
    .startdate{
     display: flex;
     flex-direction: column;
     width: 150px ;

    }

    .enddate{
        text-align: right ;
        width: 150px ;
        display: flex;
    flex-direction: column;

    }
.datepicked1{ font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    font-family: Inter;
    color: #1a212b;
    display: inline-block; 
}
.datepicked2{
    font-size: 14px;
    
    line-height: 20px;
    font-family: Inter;
    color: #728197;
  
    display: inline-block; 
}
.rightcalenderarrow{
    margin-top: 10px ;
}
.onclickopencalender {
    position: absolute;
    top: 1070px;
    left: 100px;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999; /* higher z-index to appear above other content */
  }
  
  .sdpicon1 {
    cursor: pointer;
    /* add any other styles for your icon */
  }

















  // responsive designs 
  @media (max-width: 768px) and (min-width: 320px) {

    .sdpmaincompent{
        margin-top: 20px ;
    }
    .sdpbigparent{
        display: flex ;
        flex-direction: column ;    
    }
    .sdpbigparent2{
        margin-top:24px ;
    }
    .totalAvailableShelves {
        margin-left: 22px ;
       font-size: 20px;
       font-weight: 600;

       color: #1a212b;
       text-align: left;
       }



  .sdpselfframeChild {
    width: 18px;
}
.sdpselfplaceholder {
 
    position: relative;
    line-height: 20px;
}
.gtcheckout{
    color: white ;
    width :300px ;
    height: 48px ;
  margin-top: -50px !important;
    text-align: center;

    
}
.sdpselficon2 {
    position: relative;
    width: 24px;
    height: 24px;
    object-fit: cover;
}
.sdpselficon1 {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.sdpselfinput {
    align-self: stretch;
    border-radius: 8px;
    background-color:#fff;
    border: 1px solid #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 16px; 
    gap: 16px; 
}
.sdpselfselectField {
    width: 228px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
    font-size: 14px; 
}
.sdpselfframeParent {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 20px 64px;
    box-sizing: border-box;
    text-align: left;
   
    font-size: 22px; 
    color: #1a212b;
    font-family: "Inter",sans-serif ;
} 


.sdpselflabel {
    position: relative;
    line-height: 18px;
    font-weight: 500;
}
.sdpselflabelWrapper {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.sdpselfchip {
    border-radius:  8px; 
    background-color: #fff;
    border: 1px solid #cbd4e1;
  
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding:  8px  12px;
    cursor:  pointer;
}
.sdpselfchip:hover{
    border: 1px solid #09a7b3;

}
.sdpselfchipCell {
    width: 39px;
    height: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding:16px; 
    box-sizing: border-box;
    color:  #09a7b3;
}
.sdpselfchip1 {
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #cbd4e1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px  12px;
}
.sdpselfchipCell1 {
    width: 59px;
    height: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px;
    box-sizing: border-box;
}
.sdpselfchipCell2 {
    width: 69px;
    height: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px ;
    box-sizing: border-box;
}
.sdpselfchipCellParent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px; 
    font-size: 12px;
}
.sdpselfframeDiv {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}
.sdpselfrackChild {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 16px; 
    background-color: #fff; 
    border: 1px solid #cbd4e1; 
    box-sizing: border-box;
    width: 840px;
    height: 148px;
}
.sdpselfrack:hover .sdpselfrackChild {
    border-color: #09a7b3;
  }
.sdpselfrack1 {
    position: relative;
    line-height: 18px;
    font-weight: 600;
}
.sdpselfthisIsA3 {
    position: relative;
    line-height: 24px;
    color: rgba(0, 19, 37, 0.64);
}
.sdpselfrack1Parent {
    width: 480px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap:8px; 
}
.sdpselfdiv {
    position: relative;
    line-height: 24px;
    font-weight: 600;
}
.sdpselfshelf {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
    width: 44px;
    flex-shrink: 0;
}
.sdpselfparent {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap:  4px;
}
.sdpselfframeWrapper {
    position: relative;
    width: 92px;
    height: 24px;
    font-size:22px; 
}
.sdpselfframeParent2 {
    position: absolute;
    top: 25px;
    left: 148px;
    height: 99px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap:24px;
}
#sdpselfcheckboxChild {
    position: relative;
    border-radius: 4px; 
    background-color: #fff; 
    border: 1px solid #9aa8bc;
    box-sizing: border-box;
    width: 24px;
    height: 24px;
   
}
.sdpselflabel3 {
    position: relative;
    line-height: 24px;
}
.sdpselfcheckbox1 {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap:  8px;
}
.sdpselfcheckbox {
    position: absolute;
    top: 62px;
    left: 680px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.sdpselfrack {
    position: relative;
    width: 800px;
    font-family: "Inter",sans-serif;
    height: 148px;
}
.sdpselfrackInner {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 16px;
    background-color: #fff;
    border: 1px solid  #09a7b3;
    box-sizing: border-box;
    width: 840px;
    height: 148px;
}



.sdpselfgroupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius:  4px; 
    background-color: #09a7b3;
    width: 24px;
    height: 24px;
}
.sdpselfgroupItem {
    position: absolute;
    top: 8px;
    left: 6px;
    width: 12px;
    height: 8px;
    object-fit: cover;
}
.sdpselfrectangleParent {
    position: relative;
    width: 24px;
    height: 24px;
}
.sdpselfrackChild1 {
    position: absolute;
    top: 24px;
    left: 22.65px;
    border-radius: 12px;
    width: 94.38px;
    height: 100px;
    object-fit: cover;
}
.sdpselfthisIsA5 {
    position: relative;
    line-height: 24px;
    color: #728197;
}
.sdpselfrackParent {
    flex-shrink: 0;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    font-size: 16px;
}
.sdpselfframeGroup {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
}




//   new 

.sdpbreakframeParent{
    font-family: "Inter",sans-serif;
    width : 472px ;
    padding-left: 38px ;
    padding-right: 64px ;
    padding-top: 20px ;
}
.sdpbreakrowParent{
    border: 1px solid #9aa8bc;
    box-sizing: border-box;
 background: #f6f8fb;;
 //border-radius: 20px;
 border-top-left-radius: 20px;
 border-top-right-radius: 20px;

  //  padding: 10px ;
}
.sdpbreakattribute4{
    
    padding : 10px ;
  
}
.sdpbreakrow{
    display: flex ;
    flex-direction: row ;
    border: 1px solid #9aa8bc;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-sizing: border-box;
    padding: 10px ;
}
.sdpbreakattribute1{
    font-size: 12px ;
    color : #1a212b; 
    width: 140px;
    text-align: left;
    font-family: "Inter",sans-serif ;
    font-weight: 500;
}
.sdpbreakdiv1{
    width: 100px;
    text-align: right;
}
.sdpbreakattribute2{
    width: 140px;
   margin-left: 120px ;
}
.sdpbreakattribute1E{
    font-size: 12px ;
    color : #1a212b; 
    font-weight: 500;
}
.sdpbreakvalue{
    color :#728197; ;
    font-family: "Inter",sans-serif ; 
    font-size: 14px ;
}
.sdpbreakbookingBreakup{
     font-size:  16px;
     color : #1a212b; 
     font-family: "Inter",sans-serif ;
     font-weight: 600;
     width: 280px ;
}
.sdpbreakrow3{
    display: flex ;
    gap : 30px ;
    color :#728197; ;
    font-family: "Inter",sans-serif ; 
    font-size: 16px ;
    line-height: 25px ;
    margin-top: 10px ;
    
}

.sdpbreakrow2{
     padding  : 24px;
}
.sdpbreakdiv6{
    margin-left: 20px ;
}

.sdpbreakrow8{
  color: #1a212b;
  font-size:  16px;
  font-weight: 600;
  font-family: "Inter",sans-serif ;
  display: flex ;
 margin-top: 10px ;
}
.sdpbreakpriceDetailsWrapper{
    gap : 8px ;
}
.sdpbreaktotalShelves{
    width : 210px ;
    height: 24px ;
    
}
.sdpbreakbutton{
    border: 1px solid #9aa8bc; 
    box-sizing: border-box;
    background: #09a7b3;;
    height: 48px;
    width: 325px;
    border-radius: 10px ;
    padding: 12px 16px; 
    margin : 22px ;
}

.sdpbreakbb{
    font-family: "Inter",sans-serif;
    border: 1px solid #9aa8bc;
    box-sizing: border-box;
    border-radius: 10px ;
     margin-left: 90px ;
     width : 370px ;
     
   
}


    .totalshelfavailableandcalender{
        display: flex;
        flex-direction: row;
        gap : 380px ;
        margin-left: 20px ;
    }
    .sdpselectField{
        display: flex;
        flex-direction: row;
        gap : 16px ;
    }

    .sdptags{
        font-family: "Inter",sans-serif ;
        display: flex;
        gap : 16px ;
        margin-top: 20px ;
        margin-left: 70px ;
      
    }
    .sdptagboxall{
        width: 39px ;
        height: 34px ;
        border-radius: 8px ;
        border: 1px solid #cbd4e1; 
        box-sizing: border-box;
        padding-top: 8px;
        padding-left:12px ;
        padding-right: 12px;
        padding-bottom: 8px;
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        color: #1a212b;
        text-align: left; 
    }
    .sdptagboxall:hover{
        border: 1px solid  #09a7b3;;
    }
    .sdptagbox{
        cursor: pointer;
        width: 60px ;
        height: 34px ;
       
        border-radius: 8px ;
        border: 1px solid #cbd4e1; 
        box-sizing: border-box;
        padding-top: 8px;
        padding-left:12px ;
        padding-right: 12px;
        padding-bottom: 8px;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        font-family: Inter;
        color: #1a212b;
        text-align: left; 

    }
    .sdptagbox:hover{
        border: 1px solid  #09a7b3;;
    }


    
        .totalShelvesPicked {
           
            font-size: 12px;
            line-height: 18px;
            font-weight: 500;
            font-family: Inter;
            color: #1a212b;
            text-align: left;
            display: inline-block;
            } 
.shelves {
   
    font-size: 14px;
    line-height: 20px; 
    font-family: Inter;
    color: #728197;
    text-align: left;
    display: inline-block;
    }
    .totalshlefpickeddata{
        display: flex;
       background : #F6F8FB;
        flex-direction: column;
        padding-left : 12px ;
        padding-right : 10px ;

        padding-top : 20px ;
        padding-bottom : 20px ;
        border-bottom: 1px solid #cbd4e1; 
      
    }
    .datepickedforrent{
        height: 50px ;
        border-start-start-radius : 8px  ;
        border-start-end-radius : 8px  ;
        background : #F6F8FB;
        display: flex;
        
        border-bottom: 1px solid #cbd4e1; 
        
        padding-top: 16px;
        padding-bottom:16px ;
        padding-left:12px ;
        padding-right:12px;

    }
    .startdate{
     display: flex;
     flex-direction: column;
     width: 150px ;

    }

    .enddate{
        text-align: right ;
        width: 150px ;
        display: flex;
    flex-direction: column;

    }
.datepicked1{ font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    font-family: Inter;
    color: #1a212b;
    display: inline-block; 
}
.datepicked2{
    font-size: 14px;
    
    line-height: 20px;
    font-family: Inter;
    color: #728197;
  
    display: inline-block; 
}
.rightcalenderarrow{
    margin-top: 10px ;
}
.onclickopencalender {
    position: absolute;
    top: 1070px;
    left: 100px;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999; /* higher z-index to appear above other content */
  }
  
  .sdpicon {
    cursor: pointer;
    margin-top: 80px !important;
    /* add any other styles for your icon */
  }

  }

.area_shelf{
    margin-left: 200px ;
    font-size: 16px;
    width: 200px;
    color: #728197;
}