.form1{
    font-family:  'Inter', sans-serif;


}
.fullfirstblock{
   
    margin-left: 350px ;
    border-radius: 8px; 
    background-color: #fff; 
    border: 1px solid #9aa8bc; 
    width: 800px ;
    height: 550px  !important ; 
    margin-bottom: 100px !important ;
    margin-top : 50px ;
  
  }
.label {
    flex: 1;
    position: relative;
    line-height: 18px;
    font-weight: 500;
}

.placeholder {
    flex: 1;
    position: relative;
    line-height: 24px;
}
.input {
    align-self: stretch;
    border-radius:  8px;
 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding:  12px  16px; 
    font-size: 16px;
}
#input {
  align-self: stretch;
  border-radius:  8px;
  background-color: #fff;
  border: 1px solid #9aa8bc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding:  12px  16px; 
  font-size: 16px;

}
.form{
  margin : 25px ;
}

.textField {
    width: 740px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px; 
}
.chevronDownIcon2 {
    position: relative;
    width: 24px;
    height: 24px;
}
.icon {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.input1 {
    align-self: stretch;
    border-radius: var(--br-5xs);
    background-color:  #fff;
    border: 1px solid #9aa8bc; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 16px; 
    gap:  16px; 
    font-size:  16px;
}
.selectField {
    width: 360px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px; 
}
.selectFieldParent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px; 
}
.placeholder3 {
    flex: 1;
    position: relative;
    line-height: 24px;
    display: inline-block;
    height: 72px;
}
#input3 {
    align-self: stretch;
    flex: 1;
    border-radius:  8px; 
    background-color:#fff ;
    border: 1px solid  #9aa8bc; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding:12px   16px; 
    font-size:16px; 
}
.textArea {
    width: 740px;
    height: 112px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap:4px;
}


.label5 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
}
#buttonk {
  text-decoration: none;
    align-self: stretch;
    border-radius:  8px;
    background-color: #09a7b3;
    display: flex;
    flex-direction: row;
    height : 56px ;
    width: 360px ;
    border-color: rgb(255, 255, 255);
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding:16px 24px;
    font-size: 16px;
    color: #fff;
}



#button2 {
  text-decoration: none;
  align-self: stretch;
  border-radius:  8px;
  background-color: #09a7b3;
  display: flex;
  flex-direction: row;
  height : 56px ;
  width: 360px ;
  cursor: pointer;
  border-color: rgb(255, 255, 255);
  align-items: center;
  margin-left : 20px ;
  margin-bottom: 20px ;
  justify-content: center;
  padding:16px 24px;
  font-size: 16px;
  color: #fff;
}

.textField{
    gap : 20px ;
}


.storeinfotitle {
    width: 100%;
   margin-left : 0px ;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
 
    color:#1a212b;
    text-align: left;
    display: inline-block;
    }
    

    .form1firstblock {
        position: relative;
        border-bottom: 1px solid  #cbd4e1;;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 24px;
        gap: 4px;
        text-align: left;
        font-size:16px; 
        color: #1a212b;
        }
        


        .link-style {
          text-decoration: none; /* Remove underline */
          color: inherit; /* Inherit text color from parent */
        }



//form 2 
        .businessbusinessInformation1 {
            align-self: stretch;
            font-size: 16px;
             margin-left : 0px ;
            line-height: 18px;
            font-weight: 600;
      }
      .businesstellUsAbout1 {
            align-self: stretch;
            position: relative;
            font-size:  12px; 
            line-height: 18px;
      }
      .businessstore {
            border-bottom: 1px solid #cbd4e1;
            box-sizing: border-box;
            width: 800px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 24px; 
            gap: 4px; 
      }
      .businesslabel {
          
         
      }
      .businesslabelWrapper {
         
      }
      .businessplaceholder {
            flex: 1;
            position: relative;
            line-height: 24px;
      }
      .businesschevronDownIcon6 {
            position: relative;
            width: 24px;
            height: 24px;
      }
      .businessicon {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
      }
      #businessinput {
            align-self: stretch;
            border-radius: 8px; 
           // background-color: #fff; 
            border: 1px solid #9aa8bc; 
            display: flex;
            flex-direction: row;
            width : 350px ;
            align-items: center;
            justify-content: center;
            padding: 12px  16px;
            gap: 16px; 
            font-size: 16px;
      }
      .businessselectField {
            width: 360px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 4px;
      }
      .businessselectFieldParent {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 20px;
            margin-left: 28px ;
      }
      .businessframeParent {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 20px 0px;
            gap: 20px;
            font-size: 12px;
            color: #728197; 
      }
      .businesslabel6 {
            position: relative;
            line-height: 24px;
            font-weight: 500;
      }
      .businessbutton {
            align-self: stretch;
              margin-bottom: 10px ;
            background-color: #09a7b3;
            display: flex;
            width : 750px !important;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height : 56px ;
            margin-left: -0px;
            border-radius:  8px;
            font-size: 16px;
          
      }
      .businessform {
            align-self: stretch;
            background-color:#fff;
            display: flex;
 margin-top: -50px ;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 24px; 
            color: #fff; 
      }
      .businessbusinessInformation {
          
            border-radius: 8px; 
            background-color: #fff;
            border: 1px solid #9aa8bc;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: left;
            font-size: 16px;
            color: #1a212b; 
          
      }
    
      .form1secondblock{
        margin-left: 350px ;
       border-radius: 8px; 
       background-color: #fff; 
       border: 1px solid #9aa8bc; 
       box-sizing: border-box;
       align-items: center;
       justify-content: center;
       width: 800px ;
          font-family: "Inter",sans-serif; 
       margin-top : 50px ;
       margin-bottom : 50px ;
 
     }

   

       //thirdform


       .form1thirdblock{
          margin-left: 350px ;
          border-radius: 8px; 
         // background-color: #fff; 
          border: 1px solid #9aa8bc; 
          box-sizing: border-box;
          align-items: center;
          margin-top: 50px ;
          font-family: "Inter",sans-serif ;
          width: 800px ;
          margin-bottom: 24px ;
        }
        
.thirddata{
    border-bottom: 1px solid #cbd4e1;
    box-sizing: border-box;
    display: flex;
    width : 800px ;
  
   
} 

   .fourthdatabox{
    margin : 24px ;
  
   } 
  .storetitle{
    font-weight: 600 ;
    align-self: stretch;
    color:#1a212b;
    font-size: 16px;
  } 

  .storetitledata{
    align-self: stretch;
    position: relative;
    font-size:  12px; 
    line-height: 18px;

  }
  .storetimingbutton{
     align-self: stretch;
     border-radius:  8px;
     background-color: #09a7b3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding:16px 24px;
    font-size: 16px;
    color: #fff;
    margin : 24px ;
    height : 56px ;
  }
  .markdaystable{
    display: flex;
   gap :10px ;
     margin: 20px;
  }
  .markdays{
    display: flex;
    gap:170px ;
   
  }

  .markdaystext{
    margin: 24px ;
    display: flex;
    
  
  }
  #opentiming{
    flex: 2;
    border-radius: 8px; 
   // background-color: #fff; 
    border: 1px solid #9aa8bc; 
    box-sizing: border-box;
     margin-left: -100px;
     padding: 12px 16px;
     width: 250px ;
     height: 48px ;

  }
  #closetiming {
    flex: 2;
    border-radius: 8px; 
    height: 48px ;
    width : 250px ;
   // background-color: #fff; 
    border: 1px solid #9aa8bc; 
    box-sizing: border-box;
    align-items: center;
    margin-left: -150px ;

  }
  .day{
    display: flex;
    flex : 6 ;
    margin: 24px ;
    gap : 200px ;
  }

  .dayname{
    flex: 2;
     width : 150px ;
     height: 20px ;
  }
  .daysname{
    border-radius:  5px;
    background-color: #09a7b3;
  text-align: center;
 padding-top: 15px;
padding-left: 5px;
padding-right: 5px;
 padding-bottom: -15px;
    height: 40px;
    width: 40px;

  }




  //forth block 



  .fourthblockstoreImages {
  	align-self: stretch;
  	position: relative;
  	line-height: 18px;
  	font-weight: 600;
}

.form1fourthblock{
     margin-left: 350px ;
          border-radius: 8px; 
          background-color: #fff; 
          box-sizing: border-box;
          align-items: center;
          margin-top: 50px ;
          font-family: "Inter",sans-serif ;
          width: 800px ;
          margin-bottom: 24px ;
}
.fourthblocktellUsAbout1 {
  	align-self: stretch;
  	position: relative;
  	font-size:12px;
  	line-height: 18px;
}
.fourthblockstore {
  	width: 800px;
  	display: flex;
    border: 1px solid #9aa8bc; 
    box-sizing: border-box;
  	flex-direction: column;
  	align-items: flex-start;
  	justify-content: flex-start;
  	padding:  24px;
  	gap: 4px;
}
.fourthblockocticonupload161 {
  	position: relative;
  	width: 80px;
  	height: 50px ;
    cursor: pointer;
  	overflow: hidden;
  	flex-shrink: 0;
}
.fourthblockocticonupload16Wrapper {
  	border-radius: 50px;
  	background-color:rgba(19, 109, 199, 0.1);
  	display: flex;
  	flex-direction: row;
  	align-items: flex-start;
  	justify-content: flex-start;
  	padding: 16px 36px;
}
.fourthblocktoUploadOr {
  	color: #052443;
 
}
.fourthblockclickHereToContainer {
  	position: relative;
  	line-height: 18px;
}
.fourthblocktextcontent {
  	align-self: stretch;
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  	justify-content: flex-start;
  	padding: 10px 0px;
}
.fourthblockuploadfiles {
  	border-radius: 8.66px;
  	background-color: #fff;
  	border: 1px dashed #9aa8bc;
  	box-sizing: border-box;
  	width: 752px;
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  	justify-content: center;
  	padding: 50px 32px;
  	gap: 10.93px;
}
.fourthblockuploadSection {
  	background-color:  #fff;
  	display: flex;
  	flex-direction: column;
  	align-items: flex-start;
  	justify-content: flex-start;
  	padding: 24px;
  	text-align: center;
  	font-size: 12px; 
  	color: #136dc7;
    cursor: pointer;
}
.fourthblockuploadedImage {
  	flex: 1;
  	position: relative;
  	line-height: 20px;
}
.fourthblockuploadedImageWrapper {
  	align-self: stretch;
  	display: none;
  	flex-direction: row;
  	align-items: center;
  	justify-content: flex-start;
  	padding: 12px  24px; 
  	font-size: 14px;
  	color: #000;
}
.fourthblockgroupChild {
  	position: absolute;
  	top: 0px;
  	left: 0px;
  	border-radius: 8px; 
  	width: 240px;
  	height: 132px;
  	object-fit: cover;
}
.fourthblockrectangleWrapper {
  	position: absolute;
  	top: 0px;
  	left: 0px;
  	width: 240px;
  	height: 132px;
}
.fourthblockframeChild {
  	position: absolute;
  	top: 0.07px;
  	left: 0px;
  	background: linear-gradient(180deg, rgba(0, 0, 0, 0.57), rgba(255, 255, 255, 0));
  	width: 240px;
  	height: 132px;
}
.fourthblockxcircleIcon {
  	position: absolute;
  	top: 10px;
  	left: 206px;
  	width: 24px;
  	height: 24px;
}
.fourthblockgroupParent {
  	position: relative;
  	border-radius: 8px; 
  	width: 240px;
  	height: 132px;
  	overflow: hidden;
  	flex-shrink: 0;
}
.fourthblockrectangleParent {
  	position: absolute;
  	top: 0px;
  	left: 0px;
  	width: 240px;
  	height: 132.07px;
}
.fourthblockimage {
  	overflow: hidden;
  	display: none;
  	flex-direction: row;
  	align-items: flex-start;
  	justify-content: flex-start;
  	padding: 12px 24px 24px;
  	gap: 16px;
}
.fourthblockimageSection1 {
  	position: relative;
  	border-radius: 12px;
  	background-color:#fff; 
  	border: 1px solid #9aa8bc;
  	box-sizing: border-box;
  	width: 100%;
  	overflow: hidden;
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  	justify-content: flex-start;
  	text-align: left;
  	font-size: 16px; 
  	color:#1a212b; 
  	
}
.uploadimghomecover{
  border-radius:  8px;
  background-color: #09a7b3;
  height: 40px ;
  width: 140px ;
  margin-bottom: 10px ;
}
.uploadimghome{
  color: white;
  margin: 10px ;
  margin-left: 15px;
  cursor: pointer;
 
}
.imgurlbutton{
  display:flex ;
  gap : 20px ;
  margin : 20px ;
}

#buttonk {
  text-decoration: none;
}
.labelWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  margin-top: 10px ;
  align-items: center;
  justify-content: flex-start;
}
.textField1 {
  flex: 1;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.textFieldWrapper {
  width: 740px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
// manage 
.linksummit{
  margin-top: 30px;
  
  display: flex;
  gap : 30px ;
}
.areaandcode{
  display: flex;
  gap : 20px ;
}


.linkform {
text-decoration: none;
  align-self: stretch;
  border-radius:  8px;
  background-color: #09a7b3;
  display: flex;
  flex-direction: row;
  height : 25px ;
  width: 300px ;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding:16px 24px;
  font-size: 16px;
  color: #fff;
}
#buttonimg{
  text-decoration: none;
  align-self: stretch;
  border-radius:  8px;
  background-color: #09a7b3;
  display: flex;
  flex-direction: row;
  height : 25px ;
  width: 300px ;
  border-color: rgb(255, 255, 255);
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding:16px 24px;
  font-size: 16px;
  
  color: #fff;
}