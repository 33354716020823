.sdpmapwhereYoullDisplay1 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    width: 762px;
    z-index: 0;
}
.sdpmapdivider1 {
    position: relative;
    border-top: 1px solid #cbd4e1;
    box-sizing: border-box;
    width: 1309px;
    height: 1px;
    z-index: 1;
}
.sdpmapmapIcon {
    align-self: stretch;
    position: relative;
    border-radius: 12px;
    max-width: 100%;
    overflow: hidden;
    height: 450px;
    flex-shrink: 0;
    object-fit: cover;
    z-index: 2;
}
.sdpmaplondon {
    position: relative;
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    display: flex;
    align-items: center;
    width: 332px;
    z-index: 3;
}
.sdpmapveryDynamicAnd1 {
    
    font-size:  14px;
    line-height: 24px;
    margin-top: -10px ;
    display: flex;
    align-items: center;
    width: 1300px;
    z-index: 4;
}
.sdpmaplink {
    position: relative;
    text-decoration: underline;
    line-height: 20px;
}
.sdpmaplinkCell {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 5;
    font-size: 12px;
    margin-top: -20px ;
    color: #09a7b3;
}
.sdpmapchild {
    position: absolute;
    top: 31px;
    left: 108px;
    width: 32px;
    height: 32px;
    object-fit: cover;
}


.sdpmaptooltipChild {
    position: relative;
    width: 8px;
    height: 5px;
    object-fit: cover;
}
.sdpmaptooltip {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.sdpmapdiv {
    position: absolute;
    margin: 0 !important;
    top: 262px;
    left: 495px;
    width: 247px;
    height: 63px;
    z-index: 6;
    text-align: center;
    font-size: 12px;
    color: #f6f8fb;
}
.sdpmapwhereYoullDisplayParent {
    position: relative;
    width: 100%;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 40px 64px;
    box-sizing: border-box;
    gap: 24px;
    text-align: left;
    font-size: 22px;
    color: #1a212b; 
    font-family: "Inter",sans-serif ;
}
.sdpmaplink {
    color:#09a7b3;; /* Set the color to black or any other color you prefer */
   /* Remove underline by default, if desired */
  }