
.review-container{
  // max-width: 1200px;
  width: 80%;
  margin: 10em auto;
  font-family: Inter, sans-serif;
}

.review-headline-container{
  margin: 0 auto;
}

.review-icon{
  width: 10%;
}

.review-about-us{
  color: #09a7b3;
}

.review-what-business-say-content{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
  gap: 0.2em;
  font-size: 3.5rem;
  font-weight: 600;
}

.review-slider-container{
  width: 90%;
  margin: 0 auto;
}

.review-card{
    border: 2px solid #cbd4e1;
    padding: 1em;
    position: relative;
    font-family: Inter, sans-serif;
    background-color: #fff;
    border-radius: 20px;
    width: 250px;
    height: 100%;
}

.review-card-image{
  padding-top: 0.5em;
  display: flex;
  justify-content: center;
}


.review-card-image img{
  border: 3px solid #09a7b3;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.review-card-content{
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5em;
    text-align: center;
}

.review-card-content-author{
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.2em;
}

.slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5em;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

.slider-controler .slider-arrow {
  background:#fff ;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  border: 2px solid #cbd4e1;
}

.slider-controler .slider-arrow::after {
  content: '';
}

@media (max-width: 1024px) {

  .review-icon{
    margin-left: 2em;
    width: 6%;
  }
  
  .review-what-business-say-content{
    gap: 0.2em;
    font-size: 2.5rem;
  }

  .review-card-text{
    font-size: 0.75rem;
  }
}


@media (max-width: 768px) {

  .review-slider-container{
    width: 90%;
  }

  .review-card{
    width: 250px;
  }
  
  .review-what-business-say-content{
    gap: 0.2em;
    font-size: 1.5rem;
  }

  .review-card-text{
    font-size: 0.625rem;
  }

  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-58%) !important;
  }

}

@media (max-width: 480px) {


  .review-card{
    height: 300px;
  }

  .review-card-image img{
    width: 80px;
    height: 80px;
  }

  .review-card-content-author{
    font-size: 0.75rem;
  }

  .review-card-content-company{
    font-size: 0.625rem;
  }

  .review-what-business-say-content{
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  
  .review-card-content{
    margin-top: 0.5em;
  }

  .review-card-text{
    font-size: 0.8rem;
  }

  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-58%) !important;
  }
}