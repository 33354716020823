

.checkoutlink {
    position: relative;
    text-decoration: underline;
    line-height: 20px;
}
.checkoutchevronRightIcon3 {
    position: relative;
    width: 12px;
    height: 12px;
    object-fit: cover;
}
.sdpmapdivider156{
   top :-19px ;
  height: 200px;
}
.checkouticon3 {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.checkoutlink3 {
    position: relative;
    line-height: 20px;
    color: #1a212b;
}
.checkoutbreadcrumbToDetach1 {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    color: #09a7b3;
}
.checkouticon7 {
    position: relative;
    width: 24px;
    height: 24px;
    object-fit: cover;
}
.checkouticonButton {
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0);
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px;
    cursor: pointer;
}
.checkoutbookingConfirmation {
    align-self: stretch;
    position: relative;
    line-height: 36px;
    font-weight: 600;
    display: flex;
    width : 700px ;
    align-items: center;
  
    flex-shrink: 0;
}
.checkouticonButtonParent {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    font-size: 32px;
}
.checkoutyourBooking {
    align-self: stretch;
    position: relative;
    line-height: 18px;
    font-weight: 600;
}
.checkoutdate {
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 18px;
}
.checkoutfeb22221k{
    width : 250px ;
     margin-top: 20px;
     margin-left: -50px;
    font-size: 16px;
    line-height: 24px;
    color: #1a212b;
    text-align: right;
}

.checkoutfeb22221 {
   
  
}
.checkoutnoofshlef{
   
   
}
.countshlefincheckout{
    display: flex;
    flex-direction: row;
    font-size: 16px;
    line-height: 24px;
    color: #1a212b;
    gap : 10px ;
    margin-top: 20px ;
}
.checkoutdateParent {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 178px;
    height: 46px;
}
.checkouticon9 {
    position: relative;
    cursor: pointer;
    width: 16px;
    height: 16px;
    object-fit: cover;
}
.checkouticon8 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.checkoutbutton {
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0);
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    box-sizing: border-box;
}
.checkoutgroupParent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 550px;
}
.checkoutshelfsParent {
    position: relative;
    width: 61px;
    height: 46px;
}
.checkoutframeDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 667px;
}
.checkoutframeGroup {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
    font-size: 12px;
    color: #728197;
}
.checkoutyourBookingParent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}
.checkoutabout {
    border-radius: 20px;
    border: 1px solid #cbd4e1;
    box-sizing: border-box;
    width: 800px;
    height: 196px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
}
.checkoutframeChild {
    position: absolute;
    top: 1px;
    left: 0px;
    border-radius: 16px;
    background-color: #fff;
    border: 1px solid #cbd4e1;
    box-sizing: border-box;
    width: 800px;
    height: 367px;
}
.checkoutaddress {
    position: absolute;
    top: 25px;
    left: 24px;
    line-height: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    width: 153px;
}
.checkoutmapIcon {
    position: absolute;
    top: 60px;
    left: 30px;
    border-radius: 12px;
    width: 749px;
    height: 237px;
    object-fit: cover;
}

.checkoutmappinIcon {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 24px;
    height: 24px;
    object-fit: cover;
}
.checkoutbroadwayWest1 {
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 24px;
    white-space: pre-wrap;
    display: flex;
    align-items: center;
    width: 242px;
    height: 24px;
}
.checkoutbroadwayWestLondonWrapper {
    position: absolute;
    display: flex;
    gap : 10px ;
    top: 0px;
    left: 28px;
    width: 242px;
    height: 24px;
}
.checkoutlocation {
    position: absolute;
    top: 320px;
    left: 24px;
    width: 270px;
    height: 24px;
}
.checkoutchild {
    position: absolute;
    top: 29px;
    left: 18px;
    width: 24px;
    height: 24px;
    object-fit: cover;
}
.checkouttooltipLabel {
    position: relative;
    line-height: 18px;
    font-weight: 500;
}
.checkouttooltipLabelWrapper {
    border-radius: 12px;
    background-color:  #1a212b;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px 16px;
}
.checkouttooltipChild {
    position: relative;
    width: 8px;
    height: 5px;
    object-fit: cover;
}
.checkouttooltip {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.checkoutdiv {
    position: absolute;
    top: 141px;
    left: 370px;
    width: 60px;
    height: 53px;
    text-align: center;
    font-size: 12px;
    color: #f6f8fb;
}
.checkoutrectangleParent {
    position: relative;
    width: 800px;
    height: 368px;
    flex-shrink: 0;
}
.checkoutaboutParent {
    height: 604px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
}
.checkoutimageChild {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 16px 16px 0px 0px;
    background-color: #d9d9d9;
    width: 364px;
    height: 200px;
}
.checkoutimageIcon {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 470px;
    height: 364px;
    object-fit: cover;
}
.checkoutimage1 {
    position: relative;
    border-radius: 16px 16px 0px 0px;
    width: 443px;
    height: 240px;
    overflow: hidden;
    flex-shrink: 0;
}
.checkoutbordeauxGetaway {
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: flex;
    align-items: center;
    width: 300px;
}
.checkoutdivider1 {
    position: relative;
    border-top: 1px solid #cbd4e1;
    box-sizing: border-box;
    width: 401px;
    height: 1px;
}
.checkoutbillingDetails {
    position: relative;
    line-height: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    width: 206px;
}
.checkouttotalShelves {
    flex: 1;
    position: relative;
    line-height: 24px;
}
.checkoutdiv2 {
    position: relative;
    line-height: 24px;
    text-align: right;
}
.checkoutrow {
    align-self: stretch;
    display: none;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    color: #000;
}
.checkoutdiv3 {
    position: relative;
    line-height: 24px;
    color: #1a212b;
    text-align: right;
}
.checkoutrow1 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
   
}
.checkoutpriceDetails {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}
.checkouttotal {
    flex: 1;
    position: relative;
    line-height: 18px;
    font-weight: 600;
}
.checkoutdiv8 {
    position: relative;
    line-height: 18px;
    font-weight: 600;
    text-align: right;
}
.checkoutrow6 {
    position: absolute;
    top: 142px;
    left: 0px;
    width: 400px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    color: #1a212b;
}
.checkoutpriceDetailsParent {
    position: relative;
    width: 400px;
    height: 160px;
    color: #728197;
}
.checkoutlabel {
    position: relative;
    line-height: 24px;
    font-weight: 500;
  
  

}
.confrmbook{
  
    text-decoration: none;
    color: #fff;
    font-size: 16px ;
    

}
.checkoutbutton2 {
    border-radius:  8px;
    background-color:  #09a7b3; 
    width: 400px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 16px;
    box-sizing: border-box;
    cursor: pointer;
    color: #09a7b3;
}
.checkoutbillingDetailsParent {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
    font-size: 16px;
}
.checkoutbordeauxGetawayParent {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
}
.checkoutdiv1 {
    background-color: #fff;
    border: 1px solid  #cbd4e1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 20px 20px;
}
.checkoutimageParent {
    border-radius: 20px;
    background-color:#fff;
    border: 1px solid  #cbd4e1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 22px;
}
.checkoutframeParent {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
    font-size: 16px;
}
.checkoutbreadcrumbToDetachParent {
    font-family: "Inter",sans-serif;
    flex-shrink: 0;
    display: flex;
   margin: 80px ;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 32px;
}
.checkoutbookConfirmationInner {
    position: absolute;
    top: 84px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 40px 80px;
}
.checkoutaboutUsParent {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 44px;
}
.checkoutlinks {
    position: absolute;
    top: 34px;
    left: calc(50% - 187px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.checkoutayushParashar {
    position: relative;
    line-height: 20px;
}
.checkoutprofilePictureSmilingMillenIcon1 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}
.checkoutavatar {
    position: relative;
    border-radius: 24px;
    width: 32px;
    height: 32px;
    overflow: hidden;
    flex-shrink: 0;
}
.checkoutchip {
    position: absolute;
    top: 22px;
    left: 1207px;
    border-radius: 100px;
    background-color: #f6f8fb;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    gap: 10px;
    font-size: 14px;
    color: #1a212b;
}
.checkoutlogoIcon {
    position: absolute;
    top: 32px;
    left: 52px;
    width: 142px;
    height: 28px;
    object-fit: cover;
}
.checkoutheader {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    border-bottom: 1px solid rgba(154, 168, 188, 0.2);
    box-sizing: border-box;
    width: 1440px;
    height: 92px;
    overflow: hidden;
    font-size: 16px;
    color: #728197;
}
.checkoutbookConfirmation {
    position: relative;
    background-color: #fff;
    height: 900px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #1a212b;
    font-family: Inter;
}

