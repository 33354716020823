
header {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    /* gap: 6em; */
    justify-content: space-between;
    align-items: center;
    font-family: Inter, sans-serif;
    font-weight: 500;
    padding: 1em;
    border-bottom: 1px solid #cbd4e1;
    /* max-width: 1200px; */
}
  
.logo-icon{
    filter: drop-shadow(3px 5px 5px rgb(0 0 0 / 0.4));
}


.nav-item {
    text-align: center;
}

.nav-sub-item{
    text-decoration: none;
    color: gray;
}

.btn{
    text-decoration: none;
    border: 1px solid #09a7b3;
    padding: 0.5em 1em;
    border-radius: 5px;
}

.centre .nav-sub-item:hover{
    border-bottom: 2px solid #09a7b3;
}

.btn-light{
    background-color: white;
    color: #09a7b3;
}

.btn-dark{
    background-color: #09a7b3;
    color: white;
}


.centre {
    display: grid;
}

.centre{
    grid-template-columns: repeat(3, 1fr);
    /* gap: 1em; */
    justify-content: space-evenly;
}

.right{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2em;
    /* background-color: green; */
}

.user-profile-menu{
    grid-column: 2/-1;
    background-color: #eff3f8;
    padding: 0.5em;
    border-radius: 25px;
    /* max-width: fit-content; */
}

.logged-in-profile{
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 1em;
    cursor: pointer;
    /* border: 2px solid #000; */
}

.logged-in-user-name{
    /* font-weight: 600; */
    display: flex;
    gap: 0.5em;
}

.user-icon{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 4px 4px #00000040;
}

.user-menu-dropdown{
    position: absolute;
    right: 5%;
    z-index: 5;
    background-color: #fff;
    border: 1px solid #cbd4e1;
    border-radius: 5px;

    padding: 0.5em;
}

.user-menu-items{
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.user-menu-item{
    display: flex;
    align-items: center;
    /* justify-content: flex-start; */
    gap: 1.5em;
    padding: 0.2em;
}

.user-menu-item:hover{
    background-color: #cbd4e1;
    border-radius: 5px;
    cursor: pointer;
}

.user-menu-item-text{
    text-decoration: none;
    color: #000;
}

.logout-user{
    border-top: 1px solid #cbd4e1;
    padding: 0.2em;
}


/* Mobile View */

.ham-icon{
    filter: drop-shadow(3px 5px 5px rgb(0 0 0 / 0.4));
    width: 30px;
}

.ham-dropdown{
    display: flex;
    justify-content: flex-end;
}

.guest-menu-dropdown{
    width: 150px;
    position: absolute;
    right: 5%;
    z-index: 5;
    background-color: #fff;
    border: 1px solid #cbd4e1;
    border-radius: 5px;

    padding: 0.5em;
}

.guest-menu-items{
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.guest-nav-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5em;
    padding: 0.2em;
}

.guest-nav-sub-item{
    text-decoration: none;
    color: #000;
}


@media (min-width: 1200px){
    header{
        font-size: 1rem;
        /* gap: 10em; */
    }
}

@media (max-width: 1023px){
    header{
        font-size: 0.8rem;
        /* gap: 4em; */
    }

    .right{
        gap: 1em;
    }
    
}

@media (max-width: 768px) {
    header {
        /* grid-template-columns: 1fr 1fr ;
        grid-template-areas: 
        "logo centre"
        ". buttons"; */
        gap: 0;
    }

    .centre, .right {
        /* grid-template-columns: 1fr; */
        /* grid-area: centre; */
    }

    .centre {
        /* grid-area: centre; */
    }

    .centre .nav-sub-item{
        padding: 1em ;
        font-size: 0.5rem;
    }

    .right {
        /* grid-area: buttons; */
        font-size: 0.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
    .logged-in-profile{
        font-size: 0.5rem;
    }

    .logged-in-user-name{
        font-size: 0.5rem;
    }

    .user-icon{
        width: 20px;
        height: 20px;
    }

    .logo {
        /* grid-area: logo; */
        grid-row-start: 1;
        display: flex;
        justify-content: flex-start;
    }
}

@media (max-width: 495px){
    header{
        grid-template-columns: 1fr 1fr ;
        grid-template-areas: 
        "logo buttons";
    }

    .logo{
        grid-column: 1 / 2;
        align-self: flex-start;
    }

    .user-profile-menu{
        grid-column: 2/ -1;
        width: 40%;
        max-width: 80px;
        padding: 0.5em 0.8em;
    }

    .user-profile-first-name{
        font-size: 0.8rem;
    }
    
    .justify-right{
        display: flex;
        justify-content: flex-end;
    }

    .logged-in-profile{
        justify-content: space-between;
    }

    .justify-center{
        padding: 1em ;
        display: flex;
        justify-content: center;
    }
}