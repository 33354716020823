.sdpsimilarsomeSimilarShops1 {
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 36px;
    font-weight: 600;
    display: flex;
    align-items: center;
    width: 762px;
}
.sdpsimilarsomeSimilarShopsWrapper {
    position: relative;
  
    width: 762px;
    height: 36px;
}
.sdpsimilarimageChild {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 16px 16px 0px 0px;
    background-color: #d9d9d9; 
    width: 364px;
    height: 200px;
}
.sdpsimilarimageIcon {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 364px;
    border-radius: 15px ;
    height: 364px;
    object-fit: cover;
}
.sdpsimilarimage9 {
    position: absolute;
    top: 0px;
    left: 0px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(217, 217, 217, 0));
    width: 364px;
    height: 200px;
}
.sdpsimilarlabel {
    position: relative;
    line-height: 18px;
    font-weight: 500;
}
.sdpsimilarlabelWrapper {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.sdpsimilarchip {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 8px; 
    background-color: #dbf1bc;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 12px; 
}
.sdpsimilarchip1 {
    position: absolute;
    top: 0px;
    left: 134px;
    border-radius: 8px;
    background-color:#c0d3f9; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding:8px 12px;
}
.sdpsimilartags {
    position: absolute;
    top: 150px;
    left: 16px;
    width: 215px;
    height: 34px;
}
.sdpsimilarimage8 {
    position: relative;
    border-radius: 16px 16px 0px 0px;
    width: 364px;
    height: 200px;
    overflow: hidden;
    flex-shrink: 0;
}
.sdpsimilarshopName {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 22px; 
    line-height: 24px;
    font-weight: 600;
}
.sdpsimilarmappinIcon {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 24px;
    height: 24px;
    object-fit: cover;
}
.sdpsimilarbroadwayNewYork4 {
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 20px;
    display: flex;
    align-items: center;
    width: 242.3px;
    height: 24px;
}
.sdpsimilarbroadwayNewYorkWrapper {
    position: absolute;
    top: 0px;
    left: 30px;
    width: 242.3px;
    height: 24px;
}
.sdpsimilarlocation {
    position: absolute;
    top: 40px;
    left: 0px;
    width: 272.3px;
    height: 24px;
}
.sdpsimilarstartsFrom {
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 18px;
    display: flex;
    align-items: center;
    width: 85px;
}
.sdpsimilardiv1 {
    position: relative;
    line-height: 24px;
    font-weight: 600;
}
.sdpsimilarday {
    position: relative;
    font-size: 16px; 
    line-height: 24px;
    display: inline-block;
    width: 44px;
    flex-shrink: 0;
}
.sdpsimilarparent {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap:  4px; 
}
.sdpsimilarpriceInner {
    position: absolute;
    top: 22px;
    left: 0px;
    width: 92px;
    height: 24px;
    font-size:  22px;
    color: #1a212b;
}
.sdpsimilarprice {
    position: absolute;
    top: 80px;
    left: 0px;
    width: 92px;
    height: 46px;
    font-size:12px;
    color: #728197;
}
.sdpsimilarline4 {
    position: absolute;
    top: 142px;
    left: 0px;
    background-color: #cbd4e1;
    width: 324px;
    height: 1px;
}
.sdpsimilarsqFt4 {
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 20px;
    white-space: pre-wrap;
}
.sdpsimilarextra {
    position: absolute;
    top: 159px;
    left: 0px;
    width: 216px;
    height: 20px;
    color: #728197;
}
.sdpsimilarshopNameParent {
    position: relative;
    width: 324px;
    height: 179px;
}
.sdpsimilardetails {
    border-radius: 0px 0px 16px  16px;
    background-color: #fff; 
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px  20px  20px ;
    font-size: 14px;
}
.sdpsimilardiv {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 16px; 
    border: 1px solid #cbd4e1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.sdpsimilarwrapper {
    position: relative;
    width: 364px;
    height: 411px;
    flex-shrink: 0;
}
.sdpsimilarframeParent {
    width: 1376px;
   // transform : scale(0.9) ;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
    font-size: 12px;
}
.sdpsimilargroupParent {
    position: relative;
    background-color:#f6f8fb;
    width: 100%;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 64px;
    box-sizing: border-box;
    gap: 40px;
    text-align: left;
    font-size:  32px;
    color: #1a212b;
    font-family:"Inter", sans-serif  ;
   
}

