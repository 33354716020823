.help-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3em 0;
    /* border: 5px solid #09a7b3; */
    font-family: Inter, sans-serif;
}

.help-title{
    font-size: 3rem;
    font-weight: 700;
}

.help-title-span{
    color: #09a7b3;
    margin: 0 0.2em;
}

.help-content{
    width: 40%;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 1em 0 2em ;
    font-size: 1.25rem;
}

.help-icon-arrow{
    cursor: pointer;
}

.help-synergy-btn{
    display: flex;
    gap: 1em;
    align-items: center;
}

.synergy-btn{
    text-decoration: none;
}

@media screen and (max-width: 768px) {
    .help-container{
        margin: 2em 0;
    }

    .help-title{
        font-size: 2rem;
    }

    .help-content{
        width: 300px;
        font-size: 1rem;
    }
    
}

@media screen and (max-width: 500px) {

    .help-title{
        margin: 0 auto;
    }

    .help-content{
        font-size: 0.85rem;
        width: 70%;
    }

    .synergy-btn{
        padding: 0;
        font-size: 0.75rem;
    }
    
    .help-icon-arrow{
        width: 18px;
    }
}