.logloginChild {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    height: 100% ;
   
  }
  .logloginYourAccount1 {
    position: relative;
    line-height: 40px;
    font-size: 30px ;
    font-weight: 600;
    background: linear-gradient(
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.1)
      ),
      #001325;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  
  .newtoretail{
      color: #728197 ;
  }

  .loghaveAnAccountLogin1,
  .logloginYourAccountParent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .loghaveAnAccountLogin1 {
    flex-direction: row;
    padding: 2px;
    font-size: 14px;
  }
  .logloginYourAccountParent {
    flex-direction: column;
    gap: 16px;
  }
  .loggoogleLogoIcon1 {
    position: relative;
    width: 24px;
    height: 24px;
    object-fit: cover;
    transform: rotate(-180deg);
  }
  .logcontinueWithGoogle1 {
    position: relative;
    font-weight: 600;
    background: linear-gradient(rgba(0, 19, 37, 0.8), rgba(0, 19, 37, 0.8)), #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .logcontinueWithGoogleLeftAl1 {
    flex: 1;
    border-radius: 10px;
    background-color:#f8f9fa; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 15px 15px  15px ;
  
    gap:15px ;
    transform: rotate(180deg);
  }
  .logcontinueWithGoogleLeftAlWrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .logframeDiv {
    width: 535px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    transform: rotate(180deg);
  }
  .loglabel,
  .logor {
    position: relative;
  }
  .logor {
    font-size: 18px;
    line-height: 26px;
  }
  .loglabel {
    flex: 1;
    line-height: 18px;
    font-weight: 500;
  }
  .logquestionCircleIcon2 {
    position: relative;
    width: 16px;
    height: 16px;
    object-fit: cover;
  }
  .logicon1 {
    display: flex;
    align-items: flex-start;
  }
  .logfieldTip,
  .logicon1,
  .loglabelParent {
    flex-direction: row;
    justify-content: flex-start;
  }
  .logfieldTip {
    display: none;
    align-items: flex-start;
  }
  .loglabelParent {
    align-self: stretch;
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .logplaceholder {
    flex: 1;
    position: relative;
    line-height: 24px;
  }

  .logtext4 {
    flex: 1;
    position: relative;
    line-height: 18px;
  }
  .logassistiveText,
  .logtextField,
  .logtextFieldWrapper {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .logassistiveText {
    align-self: stretch;
    display: none;
    flex-direction: row;
    padding: 0 0 0 16px; 
  }
  .logtextField,
  .logtextFieldWrapper {
    display: flex;
  }
  .logtextField {
    flex: 1;
    flex-direction: column;
    gap: 4px; 
  }
  .logtextFieldWrapper {
    width: 535px;
    height: 70px;
    flex-direction: row;
  }

  .logarrowrightIcon {
    position: relative;
    width: 24px;
    height: 24px;
    object-fit: cover;
  }
  .logbutton,
  .logbuttonWrapper {
    align-self: stretch;
    display: flex;
   
    gap : 30px ;
   
    margin-left: -20px ;
    align-items: center;
    justify-content: center;
  }
  #logbutton {
    border-radius: 8px; 
    background-color: #09a7b3; 
    flex-direction: row;
    font-family: "Inter",sans-serif ;
    cursor: pointer;
    padding: 12px  16px; 
 
    width : 230px ;
   
  }
  .logbuttonWrapper {
    flex-direction: column;
    font-size: 16px;
    color:  #fff;
  }

  .logforgetYourPassword1 {
    position: relative;
    color:   #728197;
    cursor: pointer;
    text-decoration: underline; 
  }
  
  .logframeParent1 {
    align-items: flex-start;
    gap: 32px;
    margin-top : -30px ;
    font-size: 12px;
    color: #728197;
  }
  .logframeGroup,
  .logframeParent,
  .logframeParent1 {
    display: flex;
    margin-top:20px ;
    flex-direction: column;
    justify-content: flex-start;
  }
  .logframeGroup {
    align-items: center;
    gap: 24px;
    font-size: 16px; 
    color: #000;
  }
  .logframeParent {
    position: absolute;
    top: 0;
    left: 0;
    align-items: flex-start;
    gap: 40px;
  }
  .logframeWrapper,
  .loglogoIcon {
    position: absolute;
    top: 136px;
    left: 93px;
    width: 535px;
    height: 550px;
  }
  .loglogoIcon {
    top: 64px;
    width: 142.4px;
    height: 28px;
    object-fit: cover;
  }
  .logbkgIcon,
  .loggroupParent {
    position: absolute;
    top: 0;
  }
  .loggroupParent {
    right: 0;
    width: 720px;
    font-family: "Inter",sans-serif;
    height: 820px;
    overflow: hidden;
  }
  .logbkgIcon {
    left: -429px;
    width: 1415px;
    height: 1311px;
    object-fit: cover;
  }
  .logbackground1 {
    position: absolute;
    top: -241px;
    left: 477px;
    border-radius: 50%;
    background-color: rgba(53, 131, 188, 0.8);
    filter: blur(99.57px);
    width: 490px;
    height: 490px;
    transform: rotate(9.32deg);
    transform-origin: 0 0;
    opacity: 0.2;
  }
  .logoptimiseYourInStore1 {

    margin-left: -80px;
    font-weight: 600;
    width : 600px ;

  }

  .logover500Retail {
    margin: 0;
  }
  .logformDescription {
    align-self: stretch;
    position: relative;
    font-size:  16px;
    line-height: 24px;
  }
  .logframeWrapper1,
  .logoptimiseYourInStoreRevenueParent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .logoptimiseYourInStoreRevenueParent {
    width: 436px;
    align-items: flex-start;
    gap: 8px;
  }
  .logframeWrapper1 {
    position: absolute;
    top: 0;
    left: 25px;
    align-items: center;
  }
  .logyourStore {
    position: relative;
    line-height: 27px;
    font-weight: 600;
  }
  .logimage2Icon {
    position: absolute;
    top: -339px;
    left: -68px;
    width: 428px;
    height: 642px;
    object-fit: cover;
  }
  .logimage2Wrapper,
  .logyourStoreParent {
    border-radius: 20px ;
    background-color:  #fff;
    overflow: hidden;
  }
  .logimage2Wrapper {
    position: relative;
    width: 360px;
    height: 240px;
    flex-shrink: 0;
  }
  .logyourStoreParent {
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #09a7b3; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px ;
    gap: 19px;
  }
  .logframeWrapper2 {
    position: absolute;
    top: 0;
    left: 44px;
    width: 400px;
    height: 327px;
    color:#000; 
  }
  .logframeChild,
  .logframeItem {
    position: relative;
    width: 61.6px;
  
    height: 61.6px;
    object-fit: contain;
  }
  .logframeItem {
    width: 28.31px;
    height: 28.31px;
    object-fit: cover;
  }
  .logshelfLife,
  .logweeks {
    position: relative;
    line-height: 24.72px;
  }
  .logweeks {
    font-weight: 600;
  }
  .logshelfLife {
    font-size: 14.16px;
    color:#728197; 
  }
  .loggroupParent3 {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8.24px;
    background-color: #fff;
    border: 1px solid #cbd4e1;
    box-sizing: border-box;
    width: 162.1px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8.23865795135498px 12.357986450195312px;
    gap: 4.12px;
  }
  .logcheckcircleIcon,
  .logframeWrapper3 {
    position: relative;
    width: 162.71px;
    height: 103.03px;
  }
  .logcheckcircleIcon {
    width: 32.95px;
    height: 32.95px;
    object-fit: cover;
  }
  .logmoneyTransferSuccessful1 {
    position: relative;
    line-height: 24.72px;
    display: flex;
    align-items: center;
    width: 219.35px;
    height: 31.92px;
    flex-shrink: 0;
  }
  .loggroupParent2,
  .logtag {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .logtag {
    border-radius: 8.24px;
    background-color: #fff;
    border: 1px solid #cbd4e1;
    box-sizing: border-box;
    width: 285.26px;
    height: 49.43px;
    overflow: hidden;
    flex-shrink: 0;
    flex-direction: row;
    padding: 8.23865795135498px 12.357986450195312px;
    gap: 8.24px;
    font-size: 16.48px;
    color: #191a15;
  }
  .loggroupParent2 {
    position: absolute;
    top: 48px;
    left: 0;
    
    width: 285px;
    height: 249px;
    flex-direction: column;
    gap: 17.69px;
    font-size: 21.23px;
  }
  .logframeInner {
    position: relative;
    width: 72.2px;
    height: 72.2px;
    object-fit: contain;
  }
  .logdiv,
  .logtotalShelvesRented1 {
    position: relative;
    line-height: 23.54px;
  }
  .logdiv {
    font-weight: 600;
  }
  .logtotalShelvesRented1 {
    font-size: 14.16px;
    color: #1a212b;
  }
  .logparent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10.46px;
  }
  .logsection,
  .logsectionWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .logsection {
    border-radius: 10.46px; 
    background-color: #fff ;
    border: 1.3px solid #cbd4e1;
    box-sizing: border-box;
    width: 201.39px;
    height: 44.46px;
    overflow: hidden;
    flex-shrink: 0;
    flex-direction: row;
    padding: 10.46183967590332px;
  }
  .logsectionWrapper {
    flex-direction: column;
  }
  .logdiv1,
  .logtotalIncome {
    position: absolute;
    line-height: 23.54px;
  }
  .logdiv1 {
    top: 0;
    left: 0;
    font-weight: 600;
  }
  .logtotalIncome {
    top: 29.98px;
    left: 0.19px;
    font-size: 14.16px;
    color: #a9a7b6;
  }
  .logbarChart21Icon1,
  .loggroup {
    position: relative;
    width: 87px;
    height: 53.98px;
  }
  .logbarChart21Icon1 {
    width: 26.15px;
    height: 26.15px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
  }
  .logframeParent2,
  .logframeWrapper4 {
    position: absolute;
    top: 0;
    left: 0;
  }
  .logframeParent2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 26.15px;
  }
  .logframeWrapper4 {
    width: 139.31px;
    height: 53.98px;
  }
  .logsectionInner {
    position: relative;
    width: 139.31px;
    height: 53.98px;
  }
  .logsection1 {
    border-radius: 10.46px;
    background-color: #fff;
    border: 1.3px solid #cbd4e1;
    box-sizing: border-box;
    width: 170px;
    height: 75.85px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10.46183967590332px;
    color:  #1a212b; 
  }
  .logframeChild1 {
    position: relative;
    width: 70.33px;
    height: 70.33px;
    object-fit: contain;
  }
  .loggroupParent4 {
    position: absolute;
    top: 17px;
    left: 285px;
    width: 201.39px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 10.62px;
    font-size: 20.92px;
  }
  .loggroupDiv,
  .loggroupParent1 {
    position: absolute;
    width: 486.39px;
  }
  .loggroupParent1 {
    top: 246px;
    left: 0;
    height: 327px;
    text-align: left;
    font-size: 24.4px;
    color:  #09a7b3; 
  }
  .loggroupDiv {
    top: 124px;
    left: 117px;
    height: 573px;
  }
  .loglogin,
  .logsidePage1 {
    height: 820px;
  
    overflow: hidden;
  }
  .logsidePage1 {
    position: absolute;
font-family: "Inter",sans-serif;
    top: 0;
    left: 0;
    background-color: #f5f5f5;
    width: 720px;
    text-align: center;
    font-size:  44px;
    color: #f6f8fb;
  }
  .loglogin {
    position: relative;
    
    background-color: #fff;
    width: 100%;
    text-align: left;
    font-size:  36px;
    color:  #728197; 
    font-family: 'Inter',sans-serif ;
  }
  

  .emailandpass{
    display : flex ;
    flex-direction: column ;
    gap : 50px ;
  }

  #loginput1 {
    align-self: stretch;
    border-radius:  8px;
    background-color: #fff;
    border: 1px solid  #9aa8bc;
    display: flex;
    width : 500px ;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 16px;
    font-size: 16px;
  }
  #loginput09 {
    align-self: stretch;
    border-radius:  8px;
    background-color: #fff;
    border: 1px solid  #9aa8bc;
    display: flex;
    width : 220px ;

    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 16px;
    font-size: 16px;
  }
  .loginputname{
    display: flex;
    gap : 30px ;
  }

  #loginput2 {
    align-self: stretch;
    border-radius:  8px;
    background-color: #fff;
    border: 1px solid  #9aa8bc;
    display: flex;
    width : 500px ;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 16px;
    font-size: 16px;
  }

  .eye-icon1 {
    position: absolute;
   right: 20px ;/* Adjust the right distance as needed */
    top : 58% ;
    transform: translateY(-50%);
    cursor: pointer;
    height : 20px ;
    width: 20px ;
  }


  .eye-icon2 {
    position: absolute;
   right: 20px ;/* Adjust the right distance as needed */
    top : 68% ;
    transform: translateY(-50%);
    cursor: pointer;
    height : 20px ;
    width: 20px ;
  }

 
  .logincreatebutton{
    display: flex;
    gap : 100px ;
  }
  #loginclickbutton{
   width : 200px ;
   font-size: 15px ;
   height: 50px ;
   background-color: #ffffff;
   border-radius: 8px; 
  font-family: "Inter",sans-serif;
   border: 1px solid black;
   cursor: pointer;
  }
  #loginclickbutton:hover{
    background-color: #00b6b6;
    color: white;
}
 

    #createaccountbutton{
      width : 200px ;
      font-size: 15px ;
      height: 50px ;
      background-color: #ffffff;
      border-radius: 8px; 
      border: 1px solid black;
      font-family: "Inter",sans-serif;
      cursor: pointer;
     }
     #createaccountbutton:hover{
       background-color: #00b6b6;
       color: white;
   }
   #signinbutton{
    width : 500px ;
    margin-top: 30px ;
    font-size: 15px ;
    height: 50px ;
    background-color: #ffffff;
    border-radius: 8px; 
    border: 1px solid rgb(255, 255, 255);
    font-family: "Inter",sans-serif;
    cursor: pointer;
    background-color: #00b6b6;

   }
   #signinbutton:hover{
   
    color: white;
}
   #signupbutton{
    width : 500px ;
    font-size: 15px ;
    height: 50px ;
    background-color: #ffffff;
    border-radius: 8px; 
    border: 1px solid rgb(255, 255, 255);
    background-color: #00b6b6;
    font-family: "Inter",sans-serif;
    cursor: pointer;

   }
   #signupbutton:hover{
  
    color: white;
}

.logcreateYourAccount {
  text-decoration: underline;
font-size: 13px ;

  color :  #000000;


 
}
.loginformauth{
 display: flex;
 flex-direction: column;
 gap : 20px ;
}
.Authheadlinefield{
  color :  #9aa8bc;
  font-size: 13px ;
  margin-left: 10px ;
}

.agreementoncreateaccount{
  color :  #9aa8bc;
  font-size: 13px ;
  

}
.checkboxandpolicy{
  display: flex;
  margin-top: 30px ;

}

