.calculator{
   

}
.contentchamberofcalculator{
    margin : 20px ;
   

}
.caldisc{
    font-size: 10px ;
}
.calculatorhead{
    margin: 20px ;
    font-size: 25px ;
    font-weight: 600;
}
.infocal{
    display: flex;
    gap : 30px ;
    border: 1px solid #9aa8bc;
    box-sizing: border-box;
    border-radius: 30px ;
    justify-content: center;
    align-items: center;
   
  font-family: "Inter", sans-serif;
}

.infocalsec{
    margin-top: 0px ;
    margin-right: 15px ;
    
 

}
.calmatrix{
    font-size: 17px ;
    font-weight: 600;
margin-top: -20px ;
}
.locationfactor{
      margin-top: 10px;
    font-size: 14px ;
    font-weight: 600;
}
.locationfactordetails{
    font-weight:300 ;
    font-size: 11px;
}

.lacandtile{
    display: flex;
   
}
.contentchamberofcalculator{
    width : 300px ;
    margin-left: 20px ;
}
.resultcal{
    font-size: 15px ;
    font-weight: 600;
}
.rateoutput{
    font-weight: 300;

}
.titlecal{
    font-weight: 500;
  margin-top: 20px;
  width: 200px ;
  margin-right: 20px ;
}
.titlecalinput{
    margin-top: 10px ;
    width: 300px ;

}

.fullcal{
   
    gap : 50px ;
    border: 1px solid #9aa8bc;
    box-sizing: border-box;
    border-radius: 30px ;
    justify-content: center;
    align-items: center;
    margin : 50px ;
   
     font-family: "Inter", sans-serif;
    
}
.calculatorhead{

}
.basebracet{
    color: #9aa8bc;
    font-size: 12px;
   

    font-weight: 300 !important;
}



.calbutton{
    background-color: #09A7B3;
    border-radius: 8px ;
    padding-top: 15px ;
    padding-bottom: 15px ;
    font-family: "Inter",sans-serif;
    width: 300px; 

    margin-top: 30px ;
    text-align: center;
}