.sdpbordeauxGetaway {
    align-self: stretch;
    position: relative;
    line-height: 36px;
    font-family: "Inter" ,sans-serif ;
    font-weight: 600;
}
.sdpmappinIcon {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 24px;
    height: 24px;
    object-fit: cover;
}
.sdpbroadwayWest1 {
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 24px;
    white-space: pre-wrap;
    display: flex;
    align-items: center;
    width: 800px;
    height: 24px;
}
.sdpbroadwayWestLondonWrapper {
    position: absolute;
    top: 0px;
    left: 28px;
    width: 242.3px;
    height: 24px;
}
.sdplocation {
    display: flex;
    position: relative;
    width: 270.3px;
    height: 24px;
    font-size: 16px;
}
.sdpname {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 839px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    
}
.sdpnameWrapper {
 flex: 1;
   display: flex;
    gap: 8px;
    position: relative;
    height: 68px;
}
.sdpshelvesInA1 {

  font-size: 22px;
  line-height: 24px;
  font-weight: 600;
  font-family: "Inter",sans-serif;
  color: #1a212b;
  text-align: right; 
   
}
.sdptitle {
    width: 450px;
    height: 24px;
    display: flex;
    flex-direction: column;
text-align: right;
    justify-content: flex-start;
}
.store_area_insdp{
	
font-size: 16px;

color: #728197;

text-align: right;
display: inline-block; 
}

.sdpextra {
    position: relative;
    width: 217px;
    height: 20px;
    font-size: 14px;
    color: #728197;
}

.sdptext {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 40px 0px;
    box-sizing: border-box;
    gap: 20px;
    text-align: left;
    font-size: 32px;
    color: #1a212b;
    font-family: 14px;
    transform: scale(.9);
}

.sdphead{
    font-family: "Inter" ,sans-serif ;
    
  //  
}
.sdpimage{
    margin-left: 50px;
    margin-bottom: 50px;
    display: flex;
    gap :  20px ;
    width : 800px ;
   
   
}
.sdpimg{
    border-radius: 8px ;
 
}
.sdpimgsec{
    display: flex;
    flex-direction: column ;
    gap : 17px ;
}
 


// new
.sdplinkcity {
   width : 140px ;
    text-decoration: none;
    line-height: 20px;
}
.sdpchevronRightIcon {
    width: 12px;
 
    height: 12px;
}
.sdpicon {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.sdplink2 {
    
    line-height: 20px;
    color: #1a212b;
}
.sdpbreadcrumbToDetach {
    margin-top: -50px ;
    
   margin-left: 50px ;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    font-size: 14px;
    color: #09a7b3;
    font-family: "Inter" ,sans-serif;
}

.sdplinkhome{
    
    color: #09a7b3;
}


// responsive designs 









@media (max-width: 768px) and (min-width: 320px) {
.sdpbordeauxGetaway {
    line-height: 20px;
    font-weight: 600;
    font-size: 25px ;
}
.sdpmappinIcon {
    width: 17px;
    height: 15px;
}
.sdpbroadwayWest1 {
    top: -5px;
    font-size: 15px;
    line-height: 15px;
    width: 400px;
    height: 24px;
}
.sdpnameWrapper {
    
      display: flex;
      flex-direction: column;
       gap: 8px;
       height: 20px;
   }
.sdpimage{
    margin-left: 50px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    gap :  20px ;
    width : 800px ;
   .sdpimg{
    width: 330px;
    height: 330px;
   }
}


.sdplinkcity {
    width : 120px ;
     text-decoration: none;
     font-size: 12px;
     line-height: 20px;
 }
 .sdpchevronRightIcon {
     width: 10px;
     height: 10px;
 }
 .sdpicon {
     display: flex;
     flex-direction: row;
     align-items: flex-start;
     justify-content: flex-start;
 }
 .sdplink2 {
     line-height: 20px;
     color: #1a212b;
 }
 .sdpbreadcrumbToDetach {
     margin-top: -50px ;
     gap: 10px;
     font-size: 12px;
 }
 
 .sdplinkhome{
     
     color: #09a7b3;
 }

 






.sdptext {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 40px 0px;
    box-sizing: border-box;
    gap: 20px;
    text-align: left;
    font-size: 32px;
    color: #1a212b;
    font-family: 14px;
    transform: scale(.9);
}


}
