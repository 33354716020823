.supermarketoptions{
  display: flex ;
  font-family: 'Inter', sans-serif;
  margin-top: 32px ;
  gap : 0px ;
  overflow: hidden;



}
.supermarketoptionsdata{
  flex :1.5 ;
}


.link-style {
text-decoration: none; /* Remove underline */
color: inherit; /* Inherit text color from parent */
}

.superMarketsIn{
 
  position: relative;
  font-size: 32px ; 
  line-height: 36px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color:#1a212b; 
    left : 64px ;
  display: inline-block;
  width: 150px;
  height: 38px;
}
.london {
position: relative;
font-size: 32px ;
line-height: 36px;
font-weight: 600;
font-family: 'Inter', sans-serif;
color: #09a7b3;
left : 65px ;
display: inline-block;
width: 119px;
height: 38px;
}

.results{
  position: relative;
  font-size: 16px ;
  line-height: 24px;
  font-family: 'Inter', sans-serif;
  color: #728197;
  text-align: left;
  display: flex;
  left : 64px ;
  align-items: center;
  width: 348px;
  height: 25px;
  }
  .options{
      display: flex ;
      margin-left : 64px ;
      gap : 25px ;
border-radius: 5px ;
font-size: 13px;


  } 
  .arrowdown2{
      position: relative ;
      left : 10px ;
       height: 8px;
       cursor:  pointer;
     

   
   }
   #option{
      height: 45px ;
      border-radius: 8px ;
      margin-top : 40px ;
      width : 140px !important ;
      background-color:rgb(255, 255, 255);
      border: 1px solid #cbd4e1;
        padding:  9px 9px  ;
        padding-bottom: 9px;
      
        padding-right: 9px ;
       font-family: 'Inter', sans-serif;
        color :#1a212b;
   }
   .optionshelf{
      width: 120px;
      height: 45px ;
      border-radius: 8px ;
      margin-top : 40px ;
      background-color:rgb(255, 255, 255);
      border: 1px solid #cbd4e1;
        padding:  15px 20px  ;
        padding-bottom: 20px;
        padding-right: 20px ;
font-family: 'Inter', sans-serif;
        color :#1a212b;
   }
// top dropdowns 
 
   


.descriptions{
  position: relative;
  display: grid;
grid-template-columns: repeat(2, 1fr); /* Two columns with equal width */
gap: 20px; /* Adjust the gap between items */
transform: scale(0.9);
  background-color:rgb(255, 255, 255);
  border: 1px solid #cbd4e1;
  margin-left : 30px ;
  border-radius: 10px ;
  margin-top: 20px ;
  gap : -10px  ;
margin-bottom: -20px ;
width :765px !important ;
height : 246px !important ;

}

.browsestoredata{
 margin : 20px  ;
 margin-left: -110px !important ;

}
  
.imagestore1{
width : 240px ;
  margin-left : 0px ;
  border-top-left-radius: 10px; /* Adjust the value as needed */
  border-bottom-left-radius: 10px;
  height: 245px !important ;
  
}

.dataparent{
  display: flex ;
  gap : 20px ;
  color: #1A212B ;
  font-weight :400 !important;
font-size:  14px ;
  line-height: 20px ;  

}
.ratelight{
font-family: "Inter",sans-serif;
font-weight: 400  !important;
font-size: 16px !important;
line-height: 24px  !important;
color :#1A212B  !important;
}
.commonfriend{
  background-color:rgb(255, 255, 255);
  border: 1px ;
  background :#DBF1BC ; 
  border-radius: 6px ;


}
.bestseller{
  background-color:rgb(255, 255, 255);
  border: 1px ; 
  border-radius: 6px ;
  background : #C0D3F9 ;
}

.commonfrienddata{
  margin: 12px ;
  gap : -10px ;
  
  font-family: 'Inter', sans-serif;

}
.bestsellerdata{

  margin: 12px ;
  font-family: 'Inter', sans-serif;
 

}
.shopname{
  font-size: 22px ;
  color:  #1a212b;
  margin-top: 12px ;
  font-weight: 600;
  font-family: 'Inter', sans-serif;

}
.line{
  border-top: 1px  solid #cbd4e1; 
  width : 400px 
}
.area{
  color :#728197; 
  font-size: 14px ;

  margin-top : 17px ;
}
.pricerating{
  display: flex;
  gap : 250px ;
  margin-bottom : -10px !important ;
}

.startsfrom{
  font-size: 12px  ; 
  color:  #728197;

}
.location{
  display: flex ;
}
.addresss{
  margin-bottom: 30px;
  margin-left:  30px;
  margin-top: 4px;
  color: black;
}
.mappin{
  position: absolute;
}

  .weightrate{
      font-weight: 600;
      font-family: "Inter",sans-serif;   
font-weight: 600;
font-size: 22px ;
color  : #1A212B ;
line-height : 24px ;
text-align: right;  

  }

 
  .rate{
      display: flex ;
      flex-direction: row;
      gap: 5px ;
  }
  .rateflex{
      display: flex ;
      flex-direction: row;
      gap: 5px ;

  }

  .smpgraph {
    flex: 1;
    background-color: rgb(255, 255, 255);
    border: 1px solid #ffffff;
    margin-left: -80px;
    height:1250px ;
   z-index: -1;
    overflow: hidden;
    display: flex; /* Make it a flex container */
    align-items: stretch; /* Stretch the child to fill the container */
  }

  
  /* Optionally, you can style the child element */
  .smpgraph > Mapp {
    flex: 1;
    width: 100%; /* Ensure the child takes 100% width of the parent */
    height: 100%; /* Ensure the child takes 100% height of the parent */
  }

//pagination n
.pagination {
  display: flex;
  justify-content: center;
  margin: 50px !important;
  margin-top: 50px !important;
 
}

.pagination span {
  margin: 0 5px;
  cursor: pointer;
}

.pagination span.active {
  color: #09a7b3;
  font-weight: bold;
}
.pagination {
  display: flex;
  justify-content: center;
  margin: 20px;
  margin-left: 20px;
}

.pagination span,
.pagination button {
  margin: 0 5px;
  cursor: pointer;
  padding: 8px 12px;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 5px;
}

.pagination span.active {
  background-color: #09a7b3;
  color: #fff;
  border-color:#09a7b3;
}

.pagination button.prevButton,
.pagination button.nextButton {
  background-color:#09a7b3;
  color: #fff;
}

.pagination button:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

 // changes new 
 .rating0{
  display: flex;
  gap :4px ;
  flex-direction: row;
  text-decoration: none ;
  font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 18px ;
color: #1A212B ;
}
.rating3{
  color: #728197;

  font-family: "Inter",sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; 
}
.rating2{
 
  width: 100px  !important;
  display: flex;
  flex-direction: row;
}

/// slphead and bottom

.slphead{
  border-bottom: 1px solid #CBD4E1;  
  display: flex; 
  gap : 300px ;
  font-family: "Inter",sans-serif;
  margin-top: 30px  ;

 .locationloggedindropboxslp{
  z-index: 999;
 }

}
.slpheadlogo{
  margin-bottom: 24px ;
  height: 30px; 
  margin-left: 64px ;
}

.slpbottom{
  border-top: 1px  solid #CBD4E1;  
  height: 316px ;
}
.slpcalender{
background: rgb(255, 255, 255) ;
padding: 10px ;
width  : 350px ;
}

// 
/* For screens smaller than 600px (typical mobile phones) */

.supermarketoptions {
display: flex;
flex-direction: row;
/* Other flex properties */
}

 

.line{
background-color: #cbd4e1;

margin-top: 20px ;
width: 450px;
}
.store_area{
margin: 10px;
text-decoration: none;
color: #728197  ;

}

// from here working on responsive designs




@media (max-width: 768px) and (min-width: 320px) {

  .superMarketsIn{
    font-size: 20px ; 
    font-weight: 600;
      left : 30px ;
    width: 170px;
    height: 38px;
}
.options{
 display: none;
} 
.london {
font-size: 20px ;
line-height: 36px;
font-weight: 600;
 margin-left: -30px;
}
.smpgraph {
  display: none;
}
.results{
    font-size: 12px ;
    line-height: 24px;
    width: 200px;
    margin-left: -30px;
    height: 15px;
    }
.descriptions{
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adjust the gap between items */
    transform: scale(0.9);
    background-color:rgb(255, 255, 255);
    border: 1px solid #cbd4e1;
    margin-left : 12px ;
    border-radius: 10px ;
   // margin-top: 20px ;
   // gap : -10px  ;
//margin-bottom: -20px ;
width :400px !important ;
height : 500px !important ;
}
.imagestore1{
  width : 400px ;
    margin-left : 0px ;
    border-top-left-radius: 10px; /* Adjust the value as needed */
    border-top-right-radius: 10px;
    height: 245px !important ;
    border-bottom-left-radius: 1px;  
}

.browsestoredata{
   margin : 10px  ;
   margin-left: 20px !important ;
   font-size: 14px;
}
  
.dataparent{
    gap : 10px ;
 font-size:  12px ;
}
.bestsellerdata{
  margin: 12px ;
}
.shopname{
  font-size: 20px ;
}
.line{
  border-top: 1px  solid #cbd4e1; 
  width : 360px 
}
.pricerating{
  gap : 120px ;
}
.startsfrom{
  font-size: 12px  ; 
}
.slpcalender{
  display: none;
  }
  .slphead{
    gap : 230px ;
  }
  .slpheadlogo{
    margin-bottom: 24px ;
    height: 30px; 
    margin-left: 30px ;
  }
  

// wastage scss as of now 





.ratelight{
  font-family: "Inter",sans-serif;
  font-weight: 400  !important;
  font-size: 16px !important;
  line-height: 24px  !important;
  color :#1A212B  !important;
}
.mappin{
    position: absolute;
}

    .weightrate{
        font-weight: 600;
        font-family: "Inter",sans-serif;   
  font-weight: 600;
font-size: 22px ;
color  : #1A212B ;
line-height : 24px ;
  text-align: right;  

    }

   
    .rate{
        display: flex ;
        flex-direction: row;
        gap: 5px ;
    }
    .rateflex{
        display: flex ;
        flex-direction: row;
        gap: 5px ;

    }

   // changes new 
   .rating0{
    display: flex;
    gap :4px ;
    flex-direction: row;
    text-decoration: none ;
    font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px ;
  color: #1A212B ;
  }
  .rating3{
    color: #728197;
  
    font-family: "Inter",sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
  }
  .rating2{
   
    width: 100px  !important;
    display: flex;
    flex-direction: row;
  }

  /// slphead and bottom
  





  
 
  .slpbottom{
    border-top: 1px  solid #CBD4E1;  
    height: 316px ;
  }



}