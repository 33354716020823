
header {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 6em;
  justify-content: space-between;
  align-items: center;
  font-family: Inter, sans-serif;
  font-weight: 500;
  padding: 1em;
  border-bottom: 1px solid #cbd4e1;
}

.nav-item {
  text-align: center;
}

.nav-sub-item{
  text-decoration: none;
  color: gray;
}

.btn{
  text-decoration: none;
  border: 1px solid #09a7b3;
  padding: 0.5em 1em;
  border-radius: 5px;
}

.centre .nav-sub-item:hover{
  border-bottom: 2px solid #09a7b3;
}

.btn-light{
  background-color: white;
  color: #09a7b3;
}

.btn-dark{
  background-color: #09a7b3;
  color: white;
}


.centre, .right {
  display: grid;
}

.centre{
  grid-template-columns: repeat(3, 1fr);
  /* gap: 1em; */
  justify-content: space-evenly;
}

.right{
  grid-template-columns: repeat(2, 1fr);
  gap: 2em;
  /* background-color: green; */
}


.user-profile-menu{
  background-color: #eff3f8;
  padding: 0.5em;
  border-radius: 25px;
  max-width: fit-content;
}

.logged-in-profile{
  display: flex;
  align-items: center;
  gap: 1em;
  cursor: pointer;
  /* border: 2px solid #000; */
}

.logged-in-user-name{
  /* font-weight: 600; */
  display: flex;
  gap: 0.5em;
}

.user-icon{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 4px 4px #00000040;
}

.user-menu-dropdown{
  position: absolute;
  right: 15%;
  z-index: 5;
  background-color: #fff;
  border: 1px solid #cbd4e1;
  border-radius: 5px;

  padding: 0.5em;
}

.user-menu-items{
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.user-menu-item{
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 1.5em;
  padding: 0.2em;
}

.user-menu-item:hover{
  background-color: #cbd4e1;
  border-radius: 5px;
  cursor: pointer;
}

.logout-user{
  border-top: 1px solid #cbd4e1;
  padding: 0.2em;
}


@media (min-width: 1200px){
  header{
      font-size: 1rem;
      gap: 10em;
  }
}

@media (max-width: 1023px){
  header{
      font-size: 0.8rem;
      gap: 4em;
  }

  .right{
      gap: 1em;
  }
  
}

@media (max-width: 768px) {
  header {
      grid-template-columns: 1fr 1fr ;
      /* grid-template-rows: 100px 100px; */
      grid-template-areas: 
      "logo centre"
      ". buttons";
      gap: 0;
}
.logintext{
  margin-left: 5px ;
  margin-top: 4px ;
  width: 100px ;
  text-align: center;

}

.navbarslp{
  height: 92px;
  margin-top: -10px;
  margin-bottom: -50px;
  position:relative;
  }

  .centre, .right {
      grid-template-columns: 1fr;
      grid-area: centre;
  }

  .centre {
      grid-area: centre;
  }

  .centre .nav-sub-item{
      padding: 1em ;
      font-size: 0.5rem;
  }

  .right {
      grid-area: buttons;
      font-size: 0.5rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
  }
  
  .logged-in-profile{
      font-size: 0.5rem;
  }

  .logged-in-user-name{
      font-size: 0.5rem;
  }

  .user-icon{
      width: 20px;
      height: 20px;
  }

  .logo {
      grid-area: logo;
      grid-row-start: 1;
  }
}

