

// new css of self details 
// border: 1px dashed #9aa8bc;
.selfdetailslist{
   
    border-radius: 12px;
    background-color:#fff; 
    border: 1px solid #9aa8bc;
    box-sizing: border-box;
     width: 795px ;
    
   
}
.selflistdata{
    margin: 30px ;
}
#selfnamefield{
    border-radius: 5px ;
    height: 20px ;
    width : 700px ;
    background-color:#fff; 
    border: 1px solid #9aa8bc;
    margin-bottom: 10px ;
    margin-top: -8px ;
    margin-left:-20px  ;
  
   
}
#selfdescfield{
    border-radius: 5px ;
    height: 20px ;
    width : 700px ;
    background-color:#fff; 
    border: 1px solid #9aa8bc;
    margin-bottom: 10px ;
    margin-top: -8px ;
    margin-left:-20px  ;

}
#selftypefield{
    border-radius: 5px ;
    height: 60px ;
    width : 330px ;
    background-color:#fff; 
    border: 1px solid #9aa8bc;
    margin-right: 20px ;
    margin-bottom: 20px ;
    margin-top: -8px ;
    margin-left: -20px ;

}
#selfinputratefield{
    border-radius: 5px ;
    height: 20px ;
    width : 330px ;
    background-color:#fff; 
    border: 1px solid #9aa8bc;
    margin-right: 20px ;
    margin-bottom: 20px ;
   
}
.selfform{
    font-family: 'Inter',sans-serif;
    margin-top: 100px ;
    margin-left :  300px ;
    margin-right: 300px ;
   
    margin-bottom: 100px ;
   
}



.headlinesstoredetails{
margin-left: 310px ;
font-family:  "Inter",sans-serif;
}
.selfinfo{
    font-size:  16px;
    font-weight: 600 ;
    color: #1a212b;
    margin-left: 25px ;
    margin-top: 20px ;
}
.selflistdesc{
    font-size: 12px ;
    font-weight: 400;
    align-self: stretch;
    line-height: 18px;
    margin-left: 25px ;
    margin-bottom: 25px ;
   

}


.selfname1{
    font-size: 15px; 
color: #728197;
font-weight: 600 ;
}
.selfdesc1{
    font-size: 15px; 
    color: #728197;
    font-weight: 600 ;
    

}
.selftype1{
    font-size: 15px; 
    color: #728197;
    font-weight: 600 ;

}
.selfdatarate{
    font-size: 15px; 
    color: #728197;
    font-weight: 600 ;
    margin-bottom: 5px ;

}


.selftyperent{
    display: flex;
    flex-direction: row ;
 
   
}
.selfimgehead{
    font-size:  16px;
    font-weight: 600 ;
    color: #1a212b;
    margin-top: 20px ;
    margin-left: 25px ;

}
.selfimageheaddesc{
    font-size: 12px ;
    font-weight: 400;
    align-self: stretch;
    line-height: 18px;
    margin-left: 25px ;
    margin-bottom: 24px ;
    

}
.selfuploadclickme{
    font-size: 13px ;
    font-weight: 500;
    align-self: stretch;
    line-height: 18px;
    color:   #136dc7;;
}
.selfuploadinstr{
    font-size: 13px ;
    font-weight: 400;
    align-self: stretch;
    line-height: 18px;

}
.selfinfoheadbox{
    border-bottom: 1px solid #cbd4e1;
  
}
.selfimgbox{
    border-bottom: 1px solid #cbd4e1;
     

}


.selfrateeurosign {
  
    background-color:#728197;
    line-height: 24px;
    width: 40px;
    
    height: 50px ;
    flex-shrink: 0;
}
.eurosign{
    margin : 14px ;
}
.ratevalueinput {
    flex: 1;
  
    line-height: 24px;
    color: #728197;
}
/*
.selfdatarateinput{
    position: relative;
    border-radius: 8px;
    border: 1px solid #9aa8bc;
    box-sizing: border-box;
    width: 360px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px ;
    gap: 16px;
    text-align: left;
    font-size: 16px;
    color: #333;
  
}
*/
.selfinputtype{
    margin  : 14px ;
}
.selfinputdesc{
    margin : 14px ; 
}
.selfinputname{
  margin : 14px ; 
}

// section2 imgaes 

.selfimgagebox{
    border-radius: 12px;
    background-color:#fff; 
    border: 1px solid #9aa8bc;
    box-sizing: border-box;
    margin-top: 50px ;
    width: 800px ;
    

}
.selfimgupload{

}
.selfuploadbox2{
    border-radius: 8.66px;
    background-color: #fff;
    border: 1px dashed #9aa8bc;
    box-sizing: border-box;
    width: 752px;
    display: flex;
    margin : 24px ;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 32px;
    gap: 12px;

}
.selfuploaddesc{

}
.shelfaddbuttons{
    display: flex;
    margin-bottom: 8px ;
    margin-top: -10px;
    gap : 20px ; 
    font-family: "Inter", sans-serif ;
}
.cancelbuttonselfcover{
   cursor: pointer;
    border-radius: 8px;
    background-color: #eceff4;
    width: 85px ;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    box-sizing: border-box;
    text-align: left;
    font-size: 16px;
    color: #1a212b;
   
}
.createbuttonselfcover{
  
border-radius: 8px;
background-color: #09a7b3;
cursor: pointer;
width: 85px ;
height: 40px;
overflow: hidden;
flex-shrink: 0;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
padding: 8px 16px;
box-sizing: border-box;
text-align: left;
font-size: 16px;
color: #fff;

}

.selfheadline{
    font-size: 20px ;
    margin-top: 20px ;
    margin-bottom: 100px ;
    border-bottom: 1px solid #9aa8bc ;
    font-weight: 500 ;
    display: flex;
    width: 100%; 
    gap :  700px ;
}
.addshelfsymbol{
    margin-right:  10px ;
}



  .confirmhomeself{
    color: white;
    margin: 10px ;
    margin-left: 15px;
    margin-bottom: -20px  ;
    cursor: pointer;
   width: 500px ;
   
  }

  .know_calculate{
    font-size: 10px;
    color : #9aa8bc ;
    display: flex;
    line-height: 10px ;
   

  }
  
 .logo-button{
    cursor: pointer;
    border : 1px solid #9aa8bc ; 
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    background-color: #09a7b3;
    margin-left: 10px ;
    border-radius: 8px ;
    margin-top: -10px ;
    padding: 5px ;
 }
  .statusofimageload{
   text-align:  center ;
   margin: 20px ;
  }
  .uploadimghomecoverself{
    display: flex ;
    margin  : 30px ;
    gap : 20px ;
    
  
  }

  .button{
    border-radius:  8px;
    height : 56px ;
    width: 360px ;
    background-color: #09a7b3;
  }
  #button{
    border-radius:  8px;
    height : 56px ;
    width: 360px ;
    color: white ;
    font-family:  "Inter" ,sans-serif ;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px ;
    cursor: pointer;
    border-color: white ;
    background-color: #09a7b3;
  }
  .summitandsaveself{
    color: white ;
    font-family:  "Inter" ,sans-serif ;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px ;
      margin : 16px ;
      margin-left : 110px ;
  }
  #textdeco{
    text-decoration: none;
  }
  .areaandname{
    display: flex;
    flex-direction: row;
  }