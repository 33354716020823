.dateRangeContainer {
    position: absolute;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding-right: 6px;
    padding-left: 6px;
    background-color: white;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
  }
  
  .headerCalender {
    min-height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    font-family: "Inter",sans-serif ;
    font-weight: 500;
  }
  
  .nextButton {
    position: relative;
    border: white;
    background-color: transparent;
    cursor: pointer;
    padding: 2px;
  }
  
  .nextButton:hover {
    background-color: rgba(182, 218, 213, 0.842);
    border-radius: 6px;
  }
  
  .yearMonthTitle {
    margin: 0 auto;
    text-align: center;
    cursor: default;
  }
  
  .calenderContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 12px;
  }
  
  .rightCalender {
    width: 48%;
  }
  
  .leftCalender {
    width: 48%;
  }
  .weekContainer {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin-bottom: 10px;
  }
  
  .weekCells {
    text-align: center;
    padding: 5px;
    font-family: "Inter",sans-serif ;
    font-size: 14px;
    color: #1A212B
  }
  
  .dateContainer {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
  
  .dayCells {
    display: flex;
    border-radius: 6px;
    height: 30px;
    margin: 2px;
    font-family: "Inter",sans-serif ;
    font-size: 14px;
    text-align: center;
    color: #6c6b6b;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .dayCells:hover {
    opacity: 0.7;
  }
  
  .calenderCellStart {
    background-color: #09a7b3;
    color: white;
  }
  
  .calenderCellEnd {
    background-color:#09a7b3;
    color: white;
  }
  
  .calenderCellInbetween {
    background-color: #dcf5ff;
  }
  
  .calenderCellNormal {
    background-color: white;
  }
  
  .calenderCellDisabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .calenderCellDisabled:hover {
    opacity: 0.5;
  }
  
  .accessories {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    padding-top: 6px;
    padding-bottom: 6px;
    border-top: 1px solid rgb(230, 230, 230);
    background-color: white;
  }
  
  .applyButton {
    border:#09a7b3;
    background-color:#09a7b3;
    color: white;
    margin-right: 12px;
  }
  
  .applyButton:disabled {
    opacity: 0.3;
  }
  
  .cancelButton {
    border: 1px solid rgb(230, 230, 230);
    background-color: transparent;
    color: rgb(141, 141, 141);
    margin-top: 12px;
    margin-bottom: 12px;
    margin-right: 20px;
  }
  
  .cancelButtonDark:hover {
    border: 1px solid rgb(116, 116, 116);
    color: #d4d4d4;
  }
  
  .buttonCommon {
    border-radius: 6px;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 40px;
    width: 80px;
    cursor: pointer;
  }
  
  .buttonCommon:hover {
    opacity: 0.7;
  }

  .topcalender{
    font-family: "Inter",sans-serif;
    display: flex;
    gap : 375px ;
    margin: 24px;

  
  }

  .topcalenderheadline{
    width: 119px;
    font-size: 16px;
    font-weight: 600;
    color: #1a212b;
    text-align: left;
    display: inline-block; 

  }
  .topcalenderclear{
    text-decoration: underline;
    color: #09a7b3;
    text-align: left; 
    cursor: pointer;
  }