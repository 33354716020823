
.omnichannelgroupParent{
    display: flex;
  font-family: "Inter",sans-serif;
  width: 100%;
  background-color: #F6F8FB ;
  justify-content: flex-start;
  gap: 5%;
 padding-top: 5%;
  padding-left: 8%;
  padding-bottom: 8%;
}
.omnichannelbutBeforeThat1{
    color:#728197; 
}


.omnichannellabel {
    text-decoration: none;
    color: white;
    line-height: 24px;
    font-weight: 500;
}
.omnichannelicon1 {
    display: flex;
}
.omnichannelbutton {
    border-radius: 8px;
    background-color: #09a7b3;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top:  2%  ;
    padding-bottom:  2%  ;
    width: 30%;
     gap: 5%;
    margin-top: 7% ;
    margin-left: 2% ;
    cursor: pointer;
    color: #fff;

}
.omnichannelwereBuilding{
    color: #09a7b3;
   font-weight: 500;
 
}
.omnichannelaffordableOmnichannelExperie {

     font-size: 40px;
    font-weight: 600;
    display: inline-block;
    font-family: 'Poppins' , sans-serif ;
    width: 70% ;
   line-height: 54px;
   margin-top: 3% ;

}
.omnichannelbutBeforeThat1{
    margin-top: 2% ;
}


@media (max-width: 768px) and (min-width: 320px) {
    .omnichannelgroupParent{
        flex-direction: column;

      width: 100%;
      background-color: #F6F8FB ;
      justify-content: flex-start;
      gap: 5%;
     padding-top: 5%;
      padding-left: 8%;
      padding-bottom: 8%;
    }
    

}