.loginpage {
    font-family: "Inter",sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh; 
}
.loginoptionsfull{
  
    display: flex;
    flex-direction: column;
    gap : 30px ;
}

.loginoption2{
    border : 2px  solid rgb(207, 195, 195) ;
    padding: 25px ;
    text-align: left;
    border-radius: 15px ;
    font-size: 20px ;
cursor: pointer;
}
.loginoption2:hover{
    border : 2px  solid #09a7b3 ;
}
    .loginoption3{
        border : 2px  solid rgb(255, 255, 255) ;
        padding: 25px ;
        text-align: left;
        border-radius: 15px ;
        font-size: 20px ;
        background-color: #09a7b3;
    cursor: pointer;
    }
    .loginoption3:hover{
        border : 2px  solid #09a7b3 ;
       
}

.loginoption1data{
    color : rgb(166, 161, 161)
}
.loginoption1data3{
    color : rgb(18, 17, 17)
}
.loginoption1head3{
    color: rgb(255, 255, 255);

}
.loginoption1head{
    color: rgb(0, 0, 0);

}
.loginoptions{
    display: flex;
    width: 1100px ;
    flex-direction: column;
    gap : 15px ;
}

.loginheadline{
    text-align: center;
    font-size: 40px ;
    font-weight: 500;
   
}

@media (max-width: 768px) and (min-width: 320px) {

  .loginoption1data3{
    font-size: 12px !important;
  }
  .loginoption1data{
    font-size: 12px !important;
  }
  .loginoptions{
    width: 380px !important;
    margin-left: 22px ;
  }
  .loginoption1head{
    font-size: 17px !important;
  }
  .loginoption1head3{
    font-size: 17px !important;
}
.loginheadline{
    font-size: 20px !important;
}
.loginpage {
    font-family: "Inter",sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60vh; 
}
.loginoption2{
    height: 30px !important;
   
}
.loginoption3{
    height: 30px !important;
}
 }
