

.brand-container {
    font-family:  'Inter', sans-serif;
    display: flex;
    justify-content: space-evenly;
    margin: 2em 0;
    max-width: 1200px;
    width: 100%;
}

.brand-container > div{
    flex: 1 / 2; 
    box-sizing: border-box;
}

.brand-gap-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.brand-gap-centre{
    max-width: 500px;
}

.brand-gap-subtitle{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 1em;
}

.brand-gap {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 1em;
}

.brand-gap-title p{
    margin: 0;
}

.brand-gap-title {
    font-size: 3rem;
    font-weight: 600;
    margin: 0.5em 0 1em 0;
}

.brand-gap-highlight{
    color: #728197;
    text-transform: uppercase;
    letter-spacing: .12em;
}


@media (max-width: 800px) {
    .brand-container {
        flex-direction: column;
        justify-content: space-evenly;
        margin: 2em 0;
        gap: 2em;
    }
}