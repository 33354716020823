
.slider-container {
    max-width: 1200px;
    width: 90%;
    margin: 5em auto;
    font-family: Inter, sans-serif;
    /* padding: 2em; */
    user-select: none;
}
::-webkit-scrollbar {
    display: none;
}

.slider-container .swiper{
    overflow: scroll !important;
}

#slider-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    gap: 2em;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    padding: 1em;
    text-align: center;
}

.slider-card-image img{
    width: 60px;
}

.slider-card-title {
    font-size: 1.5rem;
    font-weight: 600;
}

.slider-card-description{
    font-size: 0.85rem;
    max-width: 80%;
}

@media screen and (max-width: 480px){
    .slider-card-title {
        font-size: 1.25rem;
    }
    
    .slider-card-description{
        font-size: 0.75rem;
        max-width: 80%;
    }
}