
.solve-container{
    background-color: #f6f8fb;
    font-family: Inter, sans-serif;
    display: flex;
    flex-direction: column;
}

.solve-title{
    margin: 2em 0;
    font-size: 2rem;
    font-weight: 700;
    color: #000;
    text-align: center;
}

.solve-body p{
    margin: 0;
    padding: 0;
}

.solve-text-highlight{
    color: #09a7b3;
}

.solve-sub-title{
    font-size: 0.85rem;
    color: #728197;
    font-weight: 400;
    margin: 1em auto;
    max-width: 60%;
    padding: 0.5em;
}

.problems{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2em;
}

.solve-card{
    border: 1px solid #cbd4e1;
    border-radius: 20px;
    height: 300px;
    margin: 2em 1em;
    padding: 1em;
    background-color: white;
    display: grid;
    grid-template-columns: minmax(50px , 1fr);
    grid-template-areas:
        "a"
        "b"
        "b"
        "c"
        "d";
    align-items: start;
    text-align: center;
    cursor: pointer;
}

.solve-card:hover{
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.2);
}

.card-number{
    grid-area: a;
    font-weight: bold;
    color: #000;
    margin: 0;
    padding: 0.5em;
    justify-self: start;
}

.solve-card-*{
    margin: 0;
    padding: 0;
}

.solve-card-image{
    grid-area: b;
}

.solve-card-title{
    grid-area: c;
    font-size: 1.25rem;
    font-weight: bold;
    color: #000;
}

.solve-card-description{
    grid-area: d;
    font-size: 1rem;
    color: #728197;
}


@media (min-width: 768px) and (max-width: 1024px){
    .problems{
        flex-direction: row;
        align-items: center; 
        justify-content: center;
    }

    .solve-title{
        font-size: 3rem;
    }

    .solve-sub-title{
        font-size: 1rem;
        /* max-width: 80%; */
    }

    .solve-card{
        grid-template-columns: repeat(2, minmax(50px , 1fr));
        grid-template-areas:
            "a ."
            "b b"
            "b b"
            "c c"
            "d d";
    }

    .solve-card-title{
        font-size: 1rem;
    }

    .solve-card-description{
        font-size: 0.75rem;
    }
}

@media (min-width: 1024.5px){
    .problems{
        flex-direction: row;
    }

    .solve-title{
        font-size: 3rem;
    }

    .solve-sub-title{
        font-size: 1rem;
        /* max-width: 80%; */
    }

    .solve-card{
        grid-template-columns: repeat(3, minmax(50px , 1fr));
        grid-template-areas:
            "a . ."
            ". b ."
            ". b ."
            "c c c"
            "d d d";
        margin: 0 2em;
    }

    .solve-card-title{
        font-size: 1.25rem;
    }

    .solve-card-description{
        font-size: 1rem;
    }
    
}