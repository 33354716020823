.teammeetOur {
    position: relative;
    letter-spacing: 0.12em;
    line-height: 24px;
    text-transform: uppercase;
    text-align: left;
}
.teamteam {
    color: #09a7b3;
}
.teamamazingTeamTxtContainer {
    line-break: anywhere;
    width: 100%;
}
.teamamazingTeam {
    position: relative;
    font-size: 48px;
    line-height: 60px;
    font-weight: 600;
    font-family: 'Poppins' ,sans-serif ;
    display: flex;
    align-items: center;
    width: 746.09px;
    color: #1a212b; 
}
.teamdiscoverTheBrilliance1 {
    position: relative;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1202px;
}
.teammeetOurParent {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}
.teamframeWrapper {
    position: relative;
    width: 1202px;
    height: 124px;
}

.teamgroupParent {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: scale(0.8);
    justify-content: flex-start;
    padding: 80px 120px;
    box-sizing: border-box;
    gap: 64px;
    text-align: center;
    font-size: 16px; 
    color: #728197; 
    font-family: 'Inter' , sans-serif ; 
}

.teammember4Parent {
    position: relative;
display: flex;
gap : 50px ;
margin: 50px ;
margin-bottom: -10px ;
    height: 241.2px;
    font-size:  18px; 
    color: #1a212b; 
  
}


.carousel {
    width: 100%;
    overflow: hidden;
  }
  
  .carousel-inner {
    display: flex;
    transition: transform 1s ease; /* Adjust transition duration as needed */
  }
  
  .carousel-inner img {
    width: 100%;
    height: auto;
  }

  // new data statics here 
  .teamuserpicIcon{
    width: 100px;
    height: 100px;

  }
  .teammembername{
    color: #1a212b; 
    text-align: center; 
    font-size: 22px;
    line-height: 32px;
    font-weight: 500; 
    width: 160px; 
  }
  .socialinfoteam{
    display: flex;
    flex-direction: row;
    gap : 30px ;
    cursor: pointer;
    margin: 15px;
  }

  .teamrole{
   
    font-size: 16px;
    line-height: 24px;
    color: #1a212b;
    text-align: center;
  
  }
  .teamuserpicIcon{
    border-radius: 50px;
  }
 
  .teammemberdetails{
    margin : 10px ;
  }