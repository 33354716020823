.bankbankInformation {
    align-self: stretch;
    position: relative;
    line-height: 18px;
    font-weight: 600;
}
.banktellUsYour1 {
    align-self: stretch;
    position: relative;
    font-size: 12px; 
    line-height: 18px;
}
.bankstore {
    border-bottom: 1px solid #cbd4e1;
    box-sizing: border-box;
    width: 800px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px; 
    gap:  4px; 
}
.banklabel {
    flex: 1;
    font-size: 15px ;
    margin-top: 15px  ;

    position: relative;
    line-height: 18px;
    font-weight: 500;
}
.banklabelWrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.bankplaceholder {
    flex: 1;
    position: relative;
    line-height: 24px;
}
#bankinput {
    width: 740px;
    align-self: stretch;
    border-radius:  8px;
    background-color: #fff; 
    border: 1px solid #9aa8bc;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 16px ;
    font-size: 16px;
    margin-top: -1px ;
    margin-bottom: 20px  ;
}
.banktextField {
    width: 740px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap:  4px;
}
.banklabelContainer {
    width: 320px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.banktextField1 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px; 
    font-size: 12px;
}

/*
.bankinput2 {
    align-self: stretch;
    border-radius: 8px;
    background-color:  #fff;
    border: 1px solid #9aa8bc; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 16px
}

*/
.banktextField2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.banktextFieldParent {
    width: 740px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 20px; 
    font-size:16px; 
}
.banklabel4 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
}
.bankbutton {
    align-self: stretch;
    border-radius:  8px;
    background-color: #09a7b3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px  24px; 
    font-size:16px; 
    color: #fff ;
}

.buttonb{
    display: flex ;
   gap : 30px ;
}

#buttonb {
 
    align-self: stretch;
    border-radius:  8px;
    background-color: #09a7b3;
    display: flex;
    flex-direction: row;
    height : 56px ;
    width: 350px ;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding:16px 24px;
    font-size: 16px;
    color: #fff;
}
.bankform {
    align-self: stretch;
    background-color: #fff ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px; 
    gap: 20px; 
    font-size: 12px; 
    color: #728197;
}
.bankstoreParent {
    position: relative;
    border-radius:  8px;
    background-color: #fff; 
    border: 1px solid #cbd4e1;
    box-sizing: border-box;
    width: 800px ;
   // height : 600px ;
    margin-bottom: 100px ;
    margin-right: 200px ;
    margin-left: 300px;
   margin-top: 40px ;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size:  16px;
    color: #1a212b;
    font-family:  'Inter', sans-serif;

}

