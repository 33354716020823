/* 
.animated-image,
.animated-text {
    opacity: 0;
    animation: fadeInOut 5s infinite; 
}


@keyframes fadeInOut {
    0% {
      opacity: 1;
    }

    33.33%, 66.66% {
      opacity: 0.5;
    }

    100% {
      opacity: 0;
    }
  }
 */


.rent-now-btn{
    position: absolute;
    /* position: fixed; */
    top: 75%;
    left: 84%;
    transform: translate(-50%, -50%);
}

.hero-image-checked-yellow,
.hero-image-open-bag-green,
.hero-image-demography
{
    width: 50px;
}

.hero-image-business-type,
.hero-image-product{
    width: 30px;
}

.image-left{
    /* border: 1px solid red; */
    position: absolute;
    top: 50%;
    left: 63%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
}

.hero-image-rating,
.hero-image-rent-data{
    background-color: #ffffff;
    border: 1px solid #09a7b3;
    border-radius: 15px;
    padding: 0.75em ;
    font-weight: 600;
}

.hero-image-rent-data{
    padding: 1.25em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.rent-per-day-text, .text-highlight{
    color: #728197;
    font-size: 0.75rem;
    font-weight: 400;
}

.rent-per-day-price{
    font-size: 1.25rem;
    font-weight: 600;
}


.image-right{
    position: absolute;
    top: 30%;
    left: 85%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1em;
}

.hero-business{
    display: flex;
    gap: 0.5em;
}

.hero-image-business-type,
.hero-image-product{
    width: 90px;
    border-radius: 15px;
    padding: 0.75em ;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 0.75em;
}

.hero-image-business-type{
    background-color: #F8B0DA;
}

.hero-image-product{
    background-color: #DABEEA;
}

.hero-demography{
    background-color: #ffffff;
    border: 1px solid #09a7b3;
    border-radius: 15px;
    padding: 0.75em ;
    font-weight: 600;
    display: flex;
    gap: 0.75em;
}

.hero-demography img{
    width: 45px;
}

.hero-demogrphy-details{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

#hero-demography-age{
    font-size: 1.75rem;
    font-weight: 600;
}

.rent-button-links{
    text-decoration: none;
    color: #ffffff;
}

@media screen and (max-width: 1024px){

    .hero-image-checked-yellow,
    .hero-image-open-bag-green,
    .hero-image-demography
    {
        width: 40px;
    }

    .hero-image-business-type,
    .hero-image-product{
        width: 20px;
    }

    .hero-image-rating,
    .hero-image-rent-data{
        padding: 0.625em;
    }

    .hero-image-rent-data{
        padding: 1em;
    }

    .rent-per-day-text, .text-highlight{
        font-size: 0.625rem;
    }
    
    .rent-per-day-price{
        font-size: 1rem;
    }
    

    .hero-image-business-type,
    .hero-image-product{
        width: 80px;
        padding: 0.625em;
        font-size: 0.85rem;
        gap: 0.625em;
        border-radius: 10px;
    }

    .hero-demography{
        padding: 0.625em;
        font-size: 0.85rem;
        gap: 0.625em;
        border-radius: 10px;
    }

    #hero-demography-age{
        font-size: 1.5rem;
        font-weight: 600;
    }
}


.image-left, .image-right{
    animation: floatImageAnimation 3s ease-in-out infinite;
}


@keyframes floatImageAnimation {
    0%, 100% {
        transform: translate(-50%, -40%) translateX(0%);
    }
    50% {
        transform: translate(-50%, -50%) translateX(0%);
    }
}
