

.rent-text-mobile-container{
    width: 80%;
    height: fit-content;
    margin: 0 auto;
    text-align: center;
    padding: 2em 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mobile-beta-message{
    width: fit-content;
    border-radius: 20px;
    background-color: #e9ebec;
    padding: 0.25em 0.5em 0.25em 0.25em;
    margin: 1em auto;
}

.rent-mobile-beta-message {
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    gap: 1em;
    color: #125465;
    font-weight: 500;
}

.rent-mobile-new-notifier{
    font-size: 0.75rem;
    padding: 0.15em 0.5em;
    text-align: center;
    background-color: #125465;
    color: #ffffff;
    border-radius: 20px;
    font-weight: 300;
}


.rent-mobile-text-heading{
    font-size: 2.5rem;
    font-weight: 700;
    color: #125465;
    margin: .25em 0;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobile-text-heading-first{
    display: flex;
    justify-content: center;
    gap: 0.2em;
}

.mobile-text-highlight-shelf{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;

    animation: floatAnimation 3s ease-in-out infinite;
}

.rent-mobile-shelf-text{
    font-weight: 600;
    color: #ffffff;
}

.rent-mobile-shelf-smile-image{
    display: flex;
    justify-content: center;
    align-items: center;
}


.rent-mobile-shelf-smile-image img{
    width: 70%;
}

.rent-mobile-text-subheading{
    font-size: 1rem;
    color: #ffffff;
    margin: 1em 0 2em;
}


.rent-mobile-btn-search{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5em;
    font-size: 1rem;
}

.rent-mobile-synergy-btn{
    max-width: 60%;
    padding: 0.5em 1em;
    border-radius: 5px;
    background: linear-gradient(95.02deg,#116595,#09617d);
    color: #ffffff;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    border: none;
}

.rent-mobile-synergy-search{
    display: flex;
    align-items: center;
    gap: 0.75em;

    border-radius: 25px;
    border: none;
    background-color: #ffffff;
    padding: 0.5em 0.75em;
}

.rent-mobile-synergy-search > img{
    width: 30px;
}

.rent-mobile-synergy-search-bar{
    width: 100%;
    border: none;
    outline: none;
}

.rent-mobile-synergy-search-bar:focus{
    outline: none;
}


.rent-mobile-button-links{
    color: #fff;
    text-decoration: none;
}

@media (max-width: 495px){
    .rent-mobile-beta-message{
        font-size: 0.625rem;
        padding: 0 0.15em;
    }
    
    .rent-mobile-new-notifier {
        font-size: 0.7rem;
        padding: 0.25em 0.85em;
    }

    .rent-mobile-text-heading{
        font-size: 2rem;
    }

    .rent-mobile-text-subheading{
        font-size: 0.85rem;
    }

    .rent-mobile-synergy-btn{
        width: 60%;
        font-size: 0.85rem;
        padding: 0.5em ;
        font-weight: 500;
    }

    .rent-mobile-synergy-search > img{
        width: 20px;
    }
    
}