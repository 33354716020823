
.confirmationbookingConfirmed {
    position: absolute;
    top: 0px;
    left: 36px;
    line-height: 24px;
    font-weight: 600;
    display: flex;
    align-items: center;
    width: 206px;
}
.confirmationpositiveSolidIcon1 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 24px;
    height: 24px;
    object-fit: cover;
}
.confirmationbookingConfirmedParent {
    position: relative;
    width: 242px;
    height: 24px;
}
.confirmationitsOfficial {
    margin: 0;
}
.confirmationitsOfficialGetContainer1 {
    width: 100%;
}
.confirmationitsOfficialGetContainer {
    position: relative;
    line-height: 40px;
    font-weight: 600;
    display: flex;
    align-items: center;
    width: 662px;
}
.confirmationyourReservationIs1 {
    align-self: stretch;
    position: relative;
    font-size: 16px;;
    line-height: 24px;
    color: #728197;
}
.confirmationitsOfficialGetReadyToDiParent {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    text-align: center;
    font-size: 36px;
    color: #1a212b;
}
.confirmationframeDiv {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
}
.confirmationframeWrapper {
    position: relative;
    width: 662px;
    height: 168px;
}
.confirmationlabel {
    position: relative;
   
    line-height: 24px;
    font-weight: 500;
   
  
  
}
.confirmhome{
    text-decoration: none !important;
   
    color: aliceblue;

}
.confirmationbutton1 {
    position: absolute;
    top: 285px;
    left: calc(50% + 12px);
    border-radius: 8px ;
   // --foreground-positive: #17bf33;;
  
    width: 290px;

    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    box-sizing: border-box;
    background-color :#09a7b3;
    border : 2px  solid #09a7b3;
}
.confirmationbutton2 {
    position: absolute;
    top: 285px;
    left: calc(50% - 302px);
    border-radius: 8px;
    background-color:  rgba(255, 255, 255, 0);
    border: 2px solid #09a7b3;
    box-sizing: border-box;
    width: 290px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    color: #09a7b3;
}
.confirmationbookingId {
    font-weight: 500;
}
.confirmationlabel2 {
    position: relative;
    line-height: 18px;
}
.confirmationlabelWrapper {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.confirmationchip {
    border-radius: 8px;
    background-color: #dabeea;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
}
.confirmationline1 {
    position: absolute;
    top: 119px;
    left: 0px;
    background-color: #cbd4e1;
    width: 320px;
    height: 1px;
}
.confirmationbordeauxGetaway {
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    width: 203px;
    height: 24px;
    flex-shrink: 0;
}
.confirmationmappinIcon {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 24px;
    height: 24px;
    object-fit: cover;
}
.confirmationbroadwayNewYork1 {
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 20px;
    display: flex;
    align-items: center;
    width: 242px;
    height: 24px;
}
.confirmationbroadwayNewYorkWrapper {
    position: absolute;
    top: 0px;
    left: 30px;
    width: 24px;
    height: 24px;
}
.confirmationlocation {
    top: 30px;
    position: relative;
    width: 272px;
    height: 24px;
    font-size: 14px;
}
.confirmationbordeauxGetawayParent {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap:  12px;
}
.confirmationbutton3 {
    position: absolute;
    top: -48px;
    left: 433px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0);
    width: 40px;
    height: 40px;
    display: none;
}
.confirmationdetails {
    position: relative;
    width: 320px;
    height: 120px;
    font-size: 22px;
}
.confirmationfeb22221 {
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 20px;
}
.confirmationextra {
    position: relative;
    width: 290px;
    height: 20px;
    font-size: 14px;
    color: #728197;
}
.confirmationchipParent {
    position: absolute;
    top: -1px;
    left: 245px;
    border-radius: 0px 16px 16px 0px;
    background-color: #fff;
    border-top: 1px solid #cbd4e1;;
    border-right: 1px solid #cbd4e1;
    border-bottom: 1px solid #cbd4e1;
    box-sizing: border-box;
    width: 360px;
    height: 247px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    gap:  12px;
}
.confirmationimageChild {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 16px 0px 0px 16px;
    background-color: #d9d9d9;
    width: 245px;
    height: 245px;
}
.confirmationimageIcon {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 245px;
    height: 245px;
    object-fit: cover;
}
.confirmationimage1 {
    position: absolute;
    top: -1px;
    left: -1px;
    border-radius: 16px 0px 0px 16px;
    border-top: 1px solid #cbd4e1;
    border-bottom: 1px solid #cbd4e1;
    border-left: 1px solid #cbd4e1;
    box-sizing: border-box;
    width: 246px;
    height: 247px;
    overflow: hidden;
}
.confirmationframeParent {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 604px;
    height: 245px;
    font-size: 12px;
    color:  #1a212b;
}
.confirmationbuttonParent {
    position: relative;
    width: 604px;
    height: 325px;
    font-size: 16px;
    color: #fff;
}
.confirmationgroupParent {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 120px 80px;
    box-sizing: border-box;
    gap: 80px;
    text-align: left;
    //transform: scale(0.9);
    font-size: 22px;
    color: #17bf33;
    font-family: Inter;
}
