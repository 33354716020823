.listyouselfgettingStarted3Child {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color:#fff;
    width: 1440px;
    height: 1024px;
}
.listyouselficon9 {
    position: relative;
    width: 24px;
    height: 24px;
}
.listyouselficon8 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.listyouselfgettingStarted {
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    width: 1111px;
    flex-shrink: 0;
}
.listyouselficonParent {
    position: absolute;
    top: 0px;
    left: 240px;
    background-color: #fff;
    border-bottom: 1px solid rgba(154, 168, 188, 0.2);
    box-sizing: border-box;
    width: 1200px;
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px; 
    gap: 10px; 
}
.listyouselflabel {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    z-index: 0;
}
.listyouselfunderlineTabChild {
    position: absolute;
    margin: 0 !important;
    width: 100%;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #09a7b3;
    height: 2px;
    z-index: 1;
}
.listyouselfunderlineTab {
    background-color: #fff; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding:16px  24px;
    position: relative;
    gap: 8px; 
    color: #1a212b;
}
.listyouselflabel1 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
}
.listyouselfunderlineTab1 {
    background-color: #fff; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding:16px  24px;
}
.listyouselfunderlineTabsToDetachWrapper {
    position: absolute;
    top: 64px;
    left: 240px;
    background-color: #fff; 
    border-bottom: 1px solid rgba(154, 168, 188, 0.2); 
    box-sizing: border-box;
    width: 1200px;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 20px; 
    font-size: 16px; 
    color: #09a7b3;
}
.listyouselfletsGetYou1 {
    position: relative;
    letter-spacing: -0.01em;
    line-height: 28px;
    font-weight: 600;
    display: inline-block;
    width: 625px;
}
.listyouselftakeAFew1 {
    align-self: stretch;
    position: relative;
    font-size: 16px; 
    line-height: 24px;
    color:#728197; 
}
.listyouselfletsGetYouStartedUsernamParent {
    position: absolute;
    top: -30px;
    left: 0px;
    width: 625px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}
.listyouselfretailSetup {
    position: relative;
    letter-spacing: -0.01em;
    line-height: 28px;
    font-weight: 500;
    background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #001325;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.listyouselfsteps {
    position: relative;
    font-size: 14px;
    line-height: 20px;
}
.listyouselfretailSetupParent {
    border-bottom: 1px solid var(--outline-50); /// isue 
    box-sizing: border-box;
    width: 624px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding:  24px; 
}
.listyouselfframeChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #00b6b6;
    width: 111px;
    height: 4px;
}
.listyouselfrectangleWrapper {
    align-self: stretch;
    position: relative;
    background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #16a0a0;
    height: 4px;
}
.listyouselfframeItem {
    position: relative;
    width: 48px;
    height: 48px;
}
.listyouselfcreateYourAccount3 {
    position: relative;
    letter-spacing: -0.01em;
    width : 400px ;
    line-height: 24px      ;
    font-weight: 500    ;
    background: linear-gradient(rgba(255, 255, 255, 0.52), rgba(255, 255, 255, 0.52)), #001325;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.listyouselfrocket3 {
    position: relative;
    width: 20px;
    height: 20px;
}
.listyouselfcreateYourAccountParent {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px; 
}
.listyouselfframeWrapper1 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 181px;
    height: 24px;
}
.listyouselfcreateYourAccount4 {
    position: absolute;
    top: 27px;
    left: 2px;
    font-size: 12px; 
    line-height: 16px;
    display: inline-block;
    width: 286px;
}
.listyouselfgroupDiv {
    position: relative;
    width: 288px;
    height: 43px;
}
.listyouselfgroupParent1 {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.listyouselfframeWrapper {
    position: relative;
    width: 344px;
    height: 48px;
}
.listyouselficons6 {
    position: relative;
    width: 18px;
    height: 18px;
    display: none;
}
.listyouselfbutton1 {
    position: relative;
    line-height: 16px;
    display: none;
}
.listyouselficons7 {
    position: relative;
    width: 18px;
    height: 18px;
   
    

}
.listyouselfbutton {
    border-radius:  100px;
    background-color: #17bf33;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding:8px; 
    gap:  8px; 
  

}
.listyouselfgroupParent {
    align-self: stretch;
    background-color: #fff; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding:16px 24px; 
}
.listyouselfaddYourStore1 {
    position: relative;
    letter-spacing: -0.01em;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    width: 187px;
    flex-shrink: 0;
}
.listyouselfframeWrapper3 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 215px;
    height: 24px;
}
.listyouselfframeWrapper2 {
    position: relative;
    width: 344px;
    height: 48px;
    font-size: 16px; 
}

.listyouselfskip {
    position: relative;
    line-height: 16px;
}
.listyouselfbutton4 {
    border-radius:4px; 
    background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #610bee;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px  12px ;
}
.listyouselfbutton5 {
    border-radius:4px; 
    background-color:  #610bee; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px  12px; 
    color:#fff; }
.listyouselfbuttonParent {
    display: none;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px; 
    color: #610bee; 
}
.listyouselfgroupParent2 {
    align-self: stretch;
    background-color: #fff; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px  24px;
    font-size: 12px; 
}
.listyouselfaddShelvesIn1 {
    position: relative;
    letter-spacing: -0.01em;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    width: 247px;
    flex-shrink: 0;
}
.listyouselfaddShelvesInYourStoreWrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 316.21px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.listyouselfframeWrapper5 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 316.21px;
    height: 24px;
}
.listyouselfaddTheDetails2 {
    position: absolute;
    top: 27px;
    left: 3.38px;
    font-size:12px; 
    line-height: 16px;
    display: inline-block;
    width: 483.62px;
}
.listyouselfgroupParent7 {
    flex: 1;
    position: relative;
    height: 43px;
}
.listyouselfgroupParent6 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 543px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px; 
}
.listyouselfframeWrapper4 {
    flex: 1;
    position: relative;
    height: 48px;
    font-size: 16px;
}
.listyouselfaddBankDetailsWrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.listyouselfframeWrapper7 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 126px;
    height: 24px;
}
.listyouselfaddTheDetails3 {
    position: absolute;
    top: 27px;
    left: 2px;
    font-size:12px; 
    line-height: 16px;
    background: linear-gradient(rgba(255, 255, 255, 0.52), rgba(255, 255, 255, 0.52)), #001325;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    width: 286px;
}
.listyouselfbutton10 {
    border-radius: 8px;
    background-color:#09A7B3  ;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;;
}

.listyouselfbuttonbank {
    border-radius: 8px;
    background-color:#939696  ;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 25px;;
}
.listyouselflabelbank{
    background-color: rgba(154, 168, 188, 0.2) !important;
    font-size: 13.5px;
    line-height: 24px;
  font-weight: 500;
}

.listyouselfgroupParent8 {
    align-self: stretch;
    background-color: #ffffff  ;  
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px; 
    color:#fff; 
}
.listyouselfrocket5 {
    position: relative;
    width: 20px;
    height: 20px;
    display: none;
}
.listyouselfframeWrapper9 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 138px;
    height: 24px;
}
.listyouselfbutton11 {
    border-radius: 4px;
    background-color:#610bee;

    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding:24px  12px;
    font-size:  12px;
}
.listyouselfgroupParent11 {
    align-self: stretch;
    background-color: #fff; 
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding:  16px 24px;
    gap:  10px; 
    color: #fff; 
}
.listyouselfframeDiv {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 16px; 
}
.listyouselfframeGroup {
    position: absolute;
    top: 84px;
    left: 0px;
    border-radius: 8px; 
    background-color: #fff; 
    border: 1px solid var(--outline-50);
    box-sizing: border-box;
    width: 625px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size:20px; 
    color: #728197; 
}
.listyouselfframeParent {
    position: absolute;
    top: 120px;
    margin-left: 350px;
    width: 625px;
    height: 484px;
}
.listyouselfletGetStarted1 {
    position: absolute;
    top: 64px;
    right: 0px;
   background-color:  #f6f8fb;
    width: 1200px;
    height: 796px;
    overflow: hidden;
    text-align: center;
    font-size:  24px;
}
.listyouselfsidebarChild {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    background-color:#fff; 
    border-right: 1px solid rgba(154, 168, 188, 0.2); 
    box-sizing: border-box;
}
.listyouselflogoIcon {
    position: relative;
    height: 24px;
}
.listyouselflogo1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.listyouselflogoWrapper {
    align-self: stretch;
    background-color:#fff; 
    border-right: 1px solid rgba(154, 168, 188, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 18px 24px; 
}
.listyouselfvalue {
    position: relative;
    line-height: 18px;
    display: inline-block;
    width: 126px;
    flex-shrink: 0;
}
.listyouselficon11 {
    position: relative;
    width: 12px;
    height: 12px;
}
.listyouselfinput {
    border-radius: 4px; 
    background-color:#fff; 
    border: 1px solid rgba(154, 168, 188, 0.2); 
    box-sizing: border-box;
    width: 181px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 4px  8px; 
}
.listyouselfselectField {
    width: 160px;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    align-items: flex-start;
    background-color: #00b6b6;
    justify-content: flex-start;
    padding: 10px;
    border-radius: 10px ;
}
.listyouselfprofilePictureSmilingMillenIcon1 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}
.listyouselfavatar {
    position: relative;
    border-radius: 24px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
}
.listyouselfselectFieldParent {
    width: 240px;
    display: flex;
    gap: 20px ;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 12px; 
    box-sizing: border-box;
}
.listyouselficon13 {
    position: relative;
    width: 18px;
    height: 16px;
}
.listyouselficonGroup {
    border-radius: 8px; 
    background-color:#fff; 
    width: 208px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px; 
    cursor: pointer;
    box-sizing: border-box;
    gap: 8px;
}
.listyouselficonGroup:hover{
    background-color: #09a7b3;

}
.listyouselfdashboard {
    position: relative;
    line-height: 20px;
}
.listyouselficonParent1 {
    border-radius:8px; 
    width: 208px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px; 
    box-sizing: border-box;
    gap: 8px;
    cursor: pointer;
    color: #000;
  
}
.listyouselficonParent1:hover{
    background-color: #09a7b3;

  }

.listyouselficonParent2 {
    border-radius: 8px; 
    background-color:#ffffff; 
     cursor: pointer;
    width: 208px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px; 
    box-sizing: border-box;
    gap: 8px; 
    color: #000000; 
}
.listyouselficonParent2:hover{
    background-color: #09A7B3;
}
.listyouselfframeParent2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px  16px; 
    gap: 12px;
    font-size: 14px; 
}
.listyouselfframeParent1 {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.listyouselfchangeLogs {
    position: relative;
    line-height: 24px;
}
.listyouselficonParent3 {
    border-radius: 4px; 
    width: 208px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px; 
    box-sizing: border-box;
    gap: 8px; 
}

.listyouselficonParent3:hover{
    background-color: #09a7b3;
}
.listyouselfframeParent3 {
    position: absolute;
    bottom: 16px;
    left: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    font-size: 16px;
    color: #000; 
}


.listyouselfsidebar {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 240px;
    height: 860px;
    font-size: 12px; 
}
.listyouselfgettingStarted31 {
    position: relative;
    background-color: #fff; 
    width: 100%;
    height: 860px;
    overflow: hidden;
    text-align: left;
    font-size: 22px; 
    color: #1a212b; 
    font-family:  'Inter', sans-serif;
}

.link{
    text-decoration: none;  
    font-size: small;
    font-family:  'Inter', sans-serif;
    color: #fff;

} 



.listyouselficonlogout{
    width : 20px ;
    height: 20px ;
}

.listyouselficonlogout{
    width : 20px ;
    height: 20px ;
}